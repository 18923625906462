import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import Contexts from './Contexts/Contexts';
import useLocalStorage from '../Util/custom-hooks/UseLocalStorage';
import EventManager from '../Util/EventManager';
import Routes from './Routes';

function App() {
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: '#1E2962',
                        translucent: 'rgba(30, 41, 98, 0.3)'
                    },
                    secondary: {
                        main: '#BCCCE3',
                        translucent: 'rgba(188, 204, 227, 0.3)'
                    },
                    contrastThreshold: 3,
                    tonalOffset: 0.2
                },
                typography: {
                    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
                    body1: {
                        fontFamily: '"Comfortaa", "Arial", sans-serif'
                    }
                },
                overrides: {
                    MuiToolbar: {
                        root: {
                            height: '56px'
                        }
                    }
                }
            }),
        []
    );

    const [user, setUser] = useLocalStorage('user', {});
    const [message, setMessage] = useState('');
    const selfDestructMessageTimeout = useRef();
    const eventRef = useRef();

    const selfDestructMessage = (message) => {
        setMessage(message);
        if (selfDestructMessageTimeout.current) {
            clearTimeout(selfDestructMessageTimeout.current);
        }
        selfDestructMessageTimeout.current = setTimeout(() => setMessage(''), 10000);
    };

    useEffect(() => {
        const chatEvent = (e) => {
            EventManager.emitChatEvent();
        };

        const roomEvent = (e) => {
            /*
                Room event schema:
                {
                    eventClass: "RoomEventType"
                    eventTimestamp: "2021-05-01T17:26:55.117602"
                    eventType: "INDEX_CHANGE"
                    payload: {...},
                    receivers: [...],
                    sender: {...}
                }
            */
            EventManager.emitRoomEvent(JSON.parse(e.data));
        };

        const pathEvent = (e) => {
            EventManager.emitPathEvent(e);
        };

        const connectEvent = ({ data }) => {
            const user = JSON.parse(data);
            EventManager.emitConnectEvent(user);
        };

        if (user.userId) {
            // eventRef.current = new EventSource(`${process.env.REACT_APP_EVENTS_HOST}/events/${user.userId}`);
            eventRef.current = new EventSource(`/events/${user.userId}`);
            eventRef.current.addEventListener('RoomEventType', roomEvent);
            eventRef.current.addEventListener('ChatMessageEventType', chatEvent);
            eventRef.current.addEventListener('PresentationTrailEvent', pathEvent);
            eventRef.current.addEventListener('ConnectEventType', connectEvent);
            eventRef.current.onerror = (e) => {
                console.log('Error from event source');
            };

            eventRef.current.onopen = (e) => {
                console.log('event source connection open');
            };
            eventRef.current.onclose = (e) => {
                console.log('event source connection CLOSED');
            };
        }
        return () => {
            eventRef.current && eventRef.current.close();
        };
    }, [user, user.userId]);

    useEffect(() => {
        EventManager.listenLoginUser((data) => {
            setUser(data);
        });
        EventManager.listenKillUser(() => {
            setUser('user', {});
            window.location.replace(`/welcome`);
        });
        return () => {
            EventManager.unsetKillUser();
            EventManager.unsetLoginUser();
        };
    }, [setUser]);

    return (
        <ThemeProvider theme={theme}>
            <Contexts
                userContext={user}
                messageContext={{ message, setMessage: selfDestructMessage }}>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    open={!!message}
                    onClose={(event, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        setMessage('');
                    }}
                    message={message}
                    action={
                        <IconButton
                            aria-label='close'
                            color='inherit'
                            onClick={() => setMessage('')}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
            </Contexts>
        </ThemeProvider>
    );
}

export default App;
