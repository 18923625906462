import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import LoadingAnimation from '../loading-animation/LoadingAnimation';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import RoomSettings from './room-settings/RoomSettings';
import useLocalStorage from '../../Util/custom-hooks/UseLocalStorage';

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8'
    },
    indicator: {
        backgroundColor: '#1890ff'
    }
})((props) => <Tabs variant='scrollable' {...props} />);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium
        },
        '&:focus': {
            color: '#40a9ff'
        }
    },
    selected: {}
}))((props) => <Tab disableRipple {...props} />);

function TabContent(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

export default function ({ open, onClose }) {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const [tileSize, setTileSize] = useLocalStorage('tile-size', 135);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                <AntTabs value={value} onChange={handleChange} aria-label='ant example'>
                    <AntTab label='General' />
                    <AntTab label='Rooms' />
                    <AntTab label='Account' />
                </AntTabs>
                <br />

                {/* General settings */}
                <TabContent value={value} index={0}>
                    <Typography id='discrete-slider' gutterBottom>
                        Room Tile Size
                    </Typography>
                    <Slider
                        defaultValue={tileSize}
                        // getAriaValueText={valuetext}
                        aria-labelledby='discrete-slider'
                        valueLabelDisplay='auto'
                        step={10}
                        marks
                        min={100}
                        max={400}
                        onChange={(e, v) => setTileSize(v)}
                    />
                </TabContent>

                {/* Room Settings */}
                <TabContent value={value} index={1}>
                    <RoomSettings showLoading={(bool) => setLoading(bool)} />
                </TabContent>

                {/* Account settings */}
                <TabContent value={value} index={2}>
                    <b>TBD</b>
                </TabContent>
            </DialogContent>
            {loading ? <LoadingAnimation /> : null}
        </Dialog>
    );
}
