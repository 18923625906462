import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import CardTabSlide from './CardTabSlide';
import useLocalStorage from '../../Util/custom-hooks/UseLocalStorage';

const useStyles = makeStyles((theme) => {
    const tabOpen = {
        height: '2em',
        paddingTop: 0
    };
    return {
        card: {
            cursor: 'pointer',
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '& dd': tabOpen
        }
    };
});

function CreateRoomCard({ children, dark, tabText, onClick, iconLeft, iconRight, menu }) {
    const [tileSize] = useLocalStorage('tile-size', 135); // TODO: delete eventually - used in Settings.js
    const styles = useStyles();
    return (
        <Card
            className={styles.card}
            style={{
                maxWidth: `${tileSize}px`,
                minWidth: `${tileSize}px`,
                maxHeight: `${tileSize}px`,
                minHeight: `${tileSize}px`
            }}>
            <span style={{ position: 'absolute', left: 0, top: 0 }}>{iconLeft}</span>
            <span style={{ position: 'absolute', right: 0, top: 0 }}>{iconRight}</span>
            <ButtonBase
                onClick={onClick}
                style={{
                    width: '100%',
                    height: '100%',
                    outline: 0
                }}>
                {children}
            </ButtonBase>
            <CardTabSlide dark={dark} text={tabText} menu={menu} />
        </Card>
    );
}

export default CreateRoomCard;
