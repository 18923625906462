import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LoadingAnimation from '../loading-animation/LoadingAnimation';
import UserService from '../../services/UserService';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

function invalidEmail(email) {
    if (!email) return;
    if (!/\w+@\w+\.\w+/.test(email)) {
        return 'A vaild email is required';
    }
}

function readyForSubmit(formData) {
    return (
        Object.values(formData).every(({ value }) => value) &&
        Object.values(formData).every(({ error }) => !error)
    );
}

export default function ({ token }) {
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [formData, setFormData] = useState({ email: { value: '', error: null } });

    function submit(e) {
        e.preventDefault();
        setLoading(true);
        UserService.sendResetPasswordEmail(formData.email.value)
            .catch((error) => setSubmitError(error.response.data.message))
            .then((data) => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <form style={{ width: '100%' }} onSubmit={submit} autoComplete='on'>
                <Grid container spacing={1} alignItems='stretch' direction='column'>
                    <Grid item>
                        <TextField
                            required
                            autoComplete='email'
                            autoFocus
                            id='email'
                            label='Email'
                            variant='outlined'
                            value={formData.email.value}
                            onChange={({ target: { value } }) =>
                                setFormData({ ...formData, email: { value } })
                            }
                            onBlur={() =>
                                setFormData({
                                    ...formData,
                                    email: {
                                        ...formData.email,
                                        error: invalidEmail(formData.email.value)
                                    }
                                })
                            }
                            helperText={formData.email.error}
                            error={!!formData.email.error}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <Button
                                disabled={!readyForSubmit(formData)}
                                variant='contained'
                                color='primary'
                                type='submit'
                                fullWidth>
                                Send Password Reset Email
                            </Button>
                            <Button component={Link} to="/" color="secondary" variant="contained">
                                Cancel
                            </Button>
                            <FormHelperText error id='component-error-text'>
                                {submitError}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
            {loading ? <LoadingAnimation /> : null}
        </Fragment>
    );
}
