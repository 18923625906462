import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading() {
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.4)',
                display: 'grid',
                alignContent: 'center',
                justifyContent: 'center'
            }}>
            <CircularProgress color='secondary' />
        </div>
    );
}
