import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

/**
 *
 * This form is used for anonymous users to use a form from the landing
 * page. This will return the Entry code to the parent but not directly take them there.
 *
 */
export default function ({ onSubmit }) {
    const [entryCode, setEntryCode] = useState('');

    return (
        <Grid container justify='center'>
            <Grid item xs={1} />
            <Grid item xs={10}>
                <Box p={1}>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Grid item container alignItems='center' alignContent='center' spacing={1}>
                            <Grid item xs>
                                <TextField
                                    size='small'
                                    label='Entry Code'
                                    type='text'
                                    variant='outlined'
                                    onChange={({ target: { value } }) =>
                                        setEntryCode(value.toUpperCase())
                                    }
                                    value={entryCode}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <Button
                                        disabled={!entryCode}
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        onClick={() => onSubmit(entryCode)}>
                                        Proceed
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Grid>
            <Grid item xs={1} />
        </Grid>
    );
}
