import React from 'react';

function PhoneSVG() {
    return (
        <svg viewBox='0 0 172 172'>
            <g transform='translate(1.72,1.72) scale(0.98,0.98)'>
                <g>
                    <path d='M0,172v-172h172v172z' fill='none' stroke='none'></path>
                    <g fill='#000000' stroke='none'>
                        <path d='M48.375,10.75c-8.86035,0 -16.125,7.26465 -16.125,16.125v107.5c0,8.86035 7.26465,16.125 16.125,16.125h64.5c8.86035,0 16.125,-7.26465 16.125,-16.125v-107.5c0,-8.86035 -7.26465,-16.125 -16.125,-16.125zM48.375,21.5h64.5c3.02344,0 5.375,2.35156 5.375,5.375v107.5c0,3.02344 -2.35156,5.375 -5.375,5.375h-64.5c-3.02344,0 -5.375,-2.35156 -5.375,-5.375v-107.5c0,-3.02344 2.35156,-5.375 5.375,-5.375zM75.25,118.25v10.75h10.75v-10.75z'></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PhoneSVG;
