import axios from '../Util/AxiosIntercepted';
import EventManager from '../Util/EventManager';

/*
  USER DATA:
    {
        "user": {
            "userId": "14512",
            "email": "zachshoults@gmail.com",
            "enabled": true,
            "firstName": "Zachary",
            "gender": "Male",
            "lastName": "Shoults",
            "maidenName": null,
            "middleName": null,
            "mrn": null,
            "password": "$2a$10$mmCA2YWE20MtT0f.8HMFi.iCXoYvywRkDRgRNOfaLAlOVpeNLAbtq",
            "phoneNumber": "503-303-5055",
            "loginTimestamp": 1601818157017,
            "title": null,
            "address": null,
            "roles": [
                {
                    "roleId": 14505,
                    "name": "ROLE_ADMIN"
                }
            ],
            "username": "zachshoults@gmail.com",
            "fullName": "Zachary Shoults"
        },
        "token": {
            "access_token": "eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJ2eXUxLXNlcnZlciIsInN1YiI6InphY2hzaG91bHRzQGdtYWlsLmNvbSIsImF1ZCI6IndlYiIsImlhdCI6MTYwMTgxODQ2OCwiZXhwIjoxNjAxODQwMDY4fQ.g44ENCyajODTimL7SBMVGdgPisBvZ8zoiZY_rr2Bgzl9B4i2LX85DRZuPBCc1AAZltaAAggznsx-5sC0EoqDxw",
            "expires_in": 21600
        }
    }
*/

function login(email, password) {
    return axios
        // .post(`${process.env.REACT_APP_API_HOST}/vyu/authenticate/login`, {
        .post(`/vyu/authenticate/login`, {
            username: email,
            password
        })
        .then(({ data }) => {
            // Flatten the object
            const user = {
                ...data.user,
                ...data.token
            };
            EventManager.emitLogin(user);
        })
        .catch((error) => {
            // If backend ever wants to throw a specific error, throw that instead -> error.response.data.error
            throw new Error('Username or Password may be invalid');
        });
}

// TODO: Can only create accounts if you are already logged in
function createAccount(userData) {
    const { email, password, first, last } = userData;
    const payload = {
        email,
        enabled: true,
        firstName: first,
        lastName: last,
        password,
        roles: [
            {
                roleId: 14506,
                name: 'ROLE_USER'
            }
        ],
        username: email
    };
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/api/v1/user`, payload).then(() => login(email, password));
    return axios.post(`/vyu/api/v1/user`, payload).then(() => login(email, password));
}

const getUserById = (userId) => {
    const query = `
        query($userId: String!) {
            getUser(userId: $userId) {
                userId
                email
                firstName
                lastName
            }
        }
    `;

    const request = {
        query,
        variables: { userId }
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { getUser }
            }
        }) => getUser
    );
};

const sendResetPasswordEmail = (email) => {
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/api/v1/resetPasswordRequest`, { email });
    return axios.post(`/vyu/api/v1/resetPasswordRequest`, { email });
};

const updatePassword = (password, token) => {
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/api/v1/resetPassword`, { password, token });
    return axios.post(`/vyu/api/v1/resetPassword`, { password, token });
};

const checkPasswordResetToken = (token) => {
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/api/v1/checkResetToken`, { token });
    return axios.post(`/vyu/api/v1/checkResetToken`, { token });
};

export default {
    login,
    createAccount,
    getUserById,
    sendResetPasswordEmail,
    updatePassword,
    checkPasswordResetToken,
};
