import React, { useState, useContext, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListSubheader,
    Tooltip,
    Divider,
    Box,
    Typography
} from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import ImportExportRoundedIcon from '@material-ui/icons/ImportExportRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import UserContext from '../../App/Contexts/UserContext';
import ImgAuth from '../img-with-auth/ImgAuth';
import FileUploadButton from '../presentation-components/FileUpload';
import LinearProgress from '@material-ui/core/LinearProgress';
import RoomService from '../../services/RoomService';
import DeleteConfirm from './DeleteConfirm';
import UserService from '../../services/UserService';

export default function ({
    onClose,
    slides, // Objects with the following: { itemId, itemName, mediaType, uploadTime, uploader, visible, index }
    imgClick,
    onSort,
    onDelete,
    roomId,
    maxIndex,
    loadedImages,
    totalImages,
    adminIds, // need admin IDs to control if you can delete this image or not,
    roomOwnerId
}) {
    const [slideToDelete, setSlideToDelete] = useState(null);
    const { userId } = useContext(UserContext);
    const uniqueIds = useRef(new Set());
    const users = useRef([]); // list of all users involved in these slides to show the names

    useEffect(() => {
        if (slides) {
            const newIds = slides.map((s) => s.uploader).filter((id) => !uniqueIds.current.has(id));
            if (newIds.length) {
                newIds.forEach((id) => uniqueIds.current.add(id));
                Promise.all(newIds.map(UserService.getUserById)).then((returnedUsers) => {
                    users.current = users.current.concat(returnedUsers);
                });
            }
        }
    }, [slides]);

    return (
        <List dense>
            <DeleteConfirm
                open={slideToDelete}
                onCancel={() => setSlideToDelete(null)}
                onConfirm={() => {
                    onDelete(slideToDelete);
                    setSlideToDelete(null);
                }}
            />
            {/* Z-index to overlap the trash-can icons */}
            <ListSubheader
                disableGutters
                style={{ background: 'white', zIndex: 2, paddingTop: '20px' }}>
                <Grid container justify='space-between'>
                    {/* <Grid item xs={6} style={{ textAlign: 'center' }}>
                        <ListItemIcon style={{ justifyContent: 'center' }}>
                            <ButtonBase
                                style={{
                                    borderRadius: '50%'
                                }}>
                                <Tooltip title='Share Screen'>
                                    <DesktopWindowsRoundedIcon />
                                </Tooltip>
                            </ButtonBase>
                        </ListItemIcon>
                    </Grid> */}
                    <Grid item xs style={{ textAlign: 'center' }}>
                        <ListItemIcon style={{ justifyContent: 'center' }}>
                            <FileUploadButton roomId={roomId} maxIndex={maxIndex} />
                        </ListItemIcon>
                    </Grid>
                </Grid>
            </ListSubheader>
            <Divider />
            <ListItem disableGutters>
                <Grid container justify='space-around'>
                    <Grid item xs={9}>
                        <ListItemText primary='Image Q' align='center' />
                    </Grid>
                    <Grid item xs={3}>
                        <ButtonBase
                            style={{
                                borderRadius: '50%',
                                justifyContent: 'center',
                                padding: '4px'
                            }}
                            onClick={onSort}>
                            <Tooltip title='Reverse Order'>
                                <ImportExportRoundedIcon />
                            </Tooltip>
                        </ButtonBase>
                    </Grid>
                </Grid>
            </ListItem>
            {loadedImages < totalImages ? (
                <ListItem>
                    <Box display='flex' alignItems='center' width='100%'>
                        <Box width='100%'>
                            <LinearProgress
                                style={{ width: '100%' }}
                                variant='determinate'
                                value={(loadedImages / totalImages) * 100}
                            />
                            <Typography
                                variant='body2'
                                color='textSecondary'>{`${loadedImages} of ${totalImages}`}</Typography>
                        </Box>
                    </Box>
                </ListItem>
            ) : null}
            {/* Iterate the different image slides and display them as thumbnails */}
            {slides.map((slide, i) => (
                <ListItem key={i} disableGutters>
                    <div
                        style={{
                            paddingTop: 'calc(9 / 16 * 100%)',
                            height: 0,
                            position: 'relative',
                            overflow: 'hidden',
                            width: '100%',
                            background: '#aaa',
                            border: 'solid black 3px'
                        }}>
                        {roomOwnerId !== slide.uploader &&
                        users.current.find((u) => u.userId === slide.uploader) ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    bottom: 0,
                                    left: 0,
                                    justifyContent: 'center',
                                    padding: '0 35px',
                                    fontWeight: 'bolder',
                                    fontSize: '1.2em',
                                    fontFamily: 'Comfortaa',
                                    background: 'rgba(170, 170, 170, 0.5)',
                                    textAlign: 'center',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    // '-webkitTextStroke': '1px black',
                                    '-webkitTextFillColor': 'black'
                                }}>
                                {`${
                                    users.current.find((u) => u.userId === slide.uploader)
                                        ?.firstName
                                } ${
                                    users.current.find((u) => u.userId === slide.uploader)?.lastName
                                }`}
                            </div>
                        ) : null}

                        {adminIds.includes(userId) || userId === slide.uploader ? (
                            <ButtonBase
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    bottom: 0,
                                    right: 0,
                                    borderRadius: '50%',
                                    justifyContent: 'center',
                                    padding: '4px',
                                    background: 'rgba(255,255,255,0.5)',
                                    boxShadow: '0px 1px 1px 1px #888888'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSlideToDelete(slide);
                                }}>
                                <Tooltip title='Delete Slide'>
                                    <DeleteRoundedIcon />
                                </Tooltip>
                            </ButtonBase>
                        ) : null}

                        <div
                            style={{
                                position: 'absolute',
                                zIndex: 1,
                                bottom: 0,
                                left: 0,
                                justifyContent: 'center',
                                padding: '4px',
                                fontWeight: 'bolder',
                                fontSize: '1.3em',
                                '-WebkitTextStroke': '1px black',
                                '-webkitTextFillColor': 'white'
                            }}>
                            {slide.index}
                        </div>
                        <ImgAuth
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }}
                            presentationDrawer={true}
                            slide={slide}
                            imgClick={(img) => {
                                RoomService.setActiveImageInPresentation(
                                    roomId,
                                    slide.itemId
                                ).catch((e) => console.log(e.message));
                                imgClick(img);
                                onClose();
                            }}></ImgAuth>
                    </div>
                </ListItem>
            ))}
        </List>
    );
}
