import React from 'react';

function CloudSVG() {
    return (
        <svg viewBox='0 0 172 172'>
            <g transform='translate(12.04,12.04) scale(0.86,0.86)'>
                <g>
                    <path d='M0,172v-172h172v172z' fill='none' stroke='none'></path>
                    <g fill='#000000' stroke='none'>
                        <path d='M138.71083,71.92467c-4.8805,-24.6605 -26.617,-43.258 -52.71083,-43.258c-20.726,0 -38.6785,11.74617 -47.644,28.92467c-21.5645,2.322 -38.356,20.56833 -38.356,42.742c0,23.75033 19.24967,43 43,43h93.16667c19.78717,0 35.83333,-16.04617 35.83333,-35.83333c0,-18.92 -14.706,-34.2495 -33.28917,-35.57533zM136.16667,129h-93.16667c-15.80967,0 -28.66667,-12.857 -28.66667,-28.66667c0,-14.67733 10.9865,-26.92517 25.54917,-28.4875l7.62533,-0.82417l3.5475,-6.80117c6.837,-13.08633 20.2315,-21.2205 34.94467,-21.2205c18.75517,0 35.00917,13.33717 38.64983,31.70533l2.1285,10.73567l10.91483,0.78117c11.2015,0.7955 19.9735,10.148 19.9735,21.27783c0,11.85367 -9.64633,21.5 -21.5,21.5z'></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CloudSVG;
