import React, { useContext } from 'react';
import { Tooltip } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import ButtonBase from '@material-ui/core/ButtonBase';
import ImageService from '../../services/ImageService';
import MessageContext from '../../App/Contexts/MessageContext';

export default function ({ roomId, maxIndex }) {
    const { setMessage } = useContext(MessageContext);
    const handleCapture = ({ target }) => {
        let index = maxIndex + 1;
        [...target.files].forEach((file) => {
            const formData = new FormData();
            formData.append('file', file);
            ImageService.uploadImage(roomId, index++, formData)
                .then(() => setMessage('File uploaded'))
                .catch((e) => setMessage(e.message));
        });
    };

    return (
        <ButtonBase
            style={{
                borderRadius: '50%'
            }}>
            <input
                accept='image/*,.pdf'
                style={{ display: 'none' }}
                id='fileUpload'
                onChange={handleCapture}
                multiple
                type='file'
            />
            <label htmlFor='fileUpload' style={{ display: 'block', padding: 0, margin: 0 }}>
                <Tooltip title='Add New Slide'>
                    <AddBoxOutlinedIcon style={{ display: 'block', padding: 0, margin: 0 }} />
                </Tooltip>
            </label>
        </ButtonBase>
    );
}
