import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import UserContext from '../../App/Contexts/UserContext';
import MessageContext from '../../App/Contexts/MessageContext';
import Grid from '@material-ui/core/Grid';
import LoadingAnimation from '../loading-animation/LoadingAnimation';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import RoomService from '../../services/RoomService';
import SubscriptionService from '../../services/SubscriptionService';
import EventManager from '../../Util/EventManager';

export default function (props) {
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [roomName, setRoomName] = useState('');
    const [roomPassword, setRoomPassword] = useState('');
    const [roomEntryCode, setRoomEntryCode] = useState('');
    const [subscription, setSubscription] = useState({});
    const [exceedingLimit, setExceedingLimit] = useState(false);
    const [rooms, setRooms] = useState([]);

    const { userId } = useContext(UserContext);
    const { setMessage } = useContext(MessageContext);

    const updateRooms = () => {
        RoomService.getUserRooms(userId)
            .then((rooms) => rooms.filter((room) => room.creator.userId === userId))
            .then(setRooms)
            .catch((e) => setMessage('Unable to get rooms'));
    };

    useEffect(() => {
        updateRooms();
        EventManager.reactCreateARoomEvent(updateRooms);
        return () => {
            EventManager.unsetCreateARoomEvent();
        };
    }, []);

    useEffect(() => {
        SubscriptionService.getUserSubscriptionData(userId)
            .then(setSubscription)
            .catch(console.error);
    }, [userId]);

    useEffect(() => {
        const tooMany = subscription.numOfRooms <= rooms.length;
        setExceedingLimit(tooMany);
        if (tooMany) {
            setSubmitError(`Your subscription only allows ${subscription.numOfRooms} rooms`);
        } else {
            setSubmitError('');
        }
    }, [subscription, rooms]);

    const reset = () => {
        setSubmitError('');
        setRoomName('');
        setRoomPassword('');
        setRoomEntryCode('');
        setLoading(false);
        props.onClose();
    };

    const createRoom = (e) => {
        e.preventDefault();
        setLoading(true);
        RoomService.createRoom(userId, roomName, roomPassword, roomEntryCode)
            .then(({ data: { createRoom: { roomId, url }}}) => {
                EventManager.emitRoomEvent({ roomId, url });
                reset();
            })
            .catch((e) => {
                setLoading(false);
                setSubmitError(e.response.data.error)
            });
    };

    return (
        <Dialog open={props.open} onClose={reset} maxwidth='sm'>
            <DialogTitle>Create Room</DialogTitle>
            <DialogContent>
                <form style={{ width: '100%' }} onSubmit={createRoom}>
                    <Grid container spacing={1} alignItems='stretch' direction='column'>
                        <Grid item>
                            <TextField
                                required
                                autoFocus
                                id='room_name'
                                label='Room Name'
                                variant='outlined'
                                value={roomName}
                                onChange={({ target: { value } }) => setRoomName(value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id='room_entry_code'
                                label='Room Entry Code'
                                type='text'
                                variant='outlined'
                                placeholder='Auto-Generated'
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disabled
                                value={roomEntryCode}
                                onChange={({ target: { value } }) => setRoomEntryCode(value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <Button
                                    disabled={!roomName || exceedingLimit}
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    fullWidth>
                                    Create
                                </Button>
                                <FormHelperText error id='component-error-text'>
                                    {submitError}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            {loading ? <LoadingAnimation /> : null}
        </Dialog>
    );
}
