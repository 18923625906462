import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import MouseIcon from '@material-ui/icons/Mouse';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewSharpIcon from '@material-ui/icons/PowerSettingsNewSharp';
import CreateRoomForm from '../create-room/CreateRoomForm';
import Settings from '../settings/Settings';
import EventManager from '../../Util/EventManager';
import Avatar from '@material-ui/core/Avatar';

export default function BasePage({ menuOpen, onClose }) {
    const [createRoom, setCreateRoom] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        EventManager.reactCreateARoomEvent(() => {
            setCreateRoom(true);
        });
        return () => {
            EventManager.unsetCreateARoomEvent();
        };
    }, []);

    return (
        <>
            <Drawer open={menuOpen} onClose={onClose}>
                <List>
                    <Link to='/home' style={{ color: 'unset', textDecoration: 'unset' }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar src='/images/logo192.png' alt='VYU1 Logo' />
                            </ListItemAvatar>
                            <ListItemText
                                primary='VYU1'
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    <ListItem button key='Subscription'>
                        <ListItemIcon>
                            <Tooltip title='Subscriptions'>
                                <StorefrontIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary='Subscription' />
                    </ListItem>
                    <ListItem button key='Create Room' onClick={() => setCreateRoom(true)}>
                        <ListItemIcon>
                            <Tooltip title='Create a Room'>
                                <QueueOutlinedIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary='Create Room' />
                    </ListItem>
                    <ListItem button key='Pointers'>
                        <ListItemIcon>
                            <Tooltip title='Pointers'>
                                <MouseIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary='Pointers' />
                    </ListItem>
                    <ListItem button key='Settings' onClick={() => setShowSettings(true)}>
                        <ListItemIcon>
                            <Tooltip title='Settings'>
                                <SettingsIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary='Settings' />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    {['Log Out'].map((text) => (
                        <ListItem button key={text} onClick={() => EventManager.emitKillUser()}>
                            <ListItemIcon>
                                <Tooltip title='Log Out'>
                                    <PowerSettingsNewSharpIcon />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>

            {/* Show the Create New Room Modal */}
            <CreateRoomForm
                open={createRoom}
                onClose={() => {
                    setCreateRoom(false);
                }}
            />

            {/* Show the Settings Modal */}
            <Settings
                open={showSettings}
                onClose={() => {
                    setShowSettings(false);
                }}
            />
        </>
    );
}
