import axios from '../Util/AxiosIntercepted';

/*
{
    query:"
        mutation ($room: NewRoom!) {
            createRoom(room: $room) {
                creator {
                userId
                username
            }
            name
            roommates {
                role
                id {
                    roomId
                    userId
            }
            banned
            }
            roomId
            url
        }
        }",

    variables:{"room":{"creator":{"userId":"14512"},"name":"Stephen King Discussion3","url":"fhuw8AEkl"}}}
*/

function getImageBlob(roomId, fileName) {
    return axios
        // .get(`${process.env.REACT_APP_API_HOST}/vyu/api/v1/presentation-item?filename=${fileName}&roomId=${roomId}`, {
        .get(`/vyu/api/v1/presentation-item?filename=${fileName}&roomId=${roomId}`, {
            responseType: 'blob'
        })
        .then(({ data }) => data)
        .catch((e) => {
            throw new Error(e.response.data.message);
        });
}

function uploadImage(roomId, index, formData) {
    // TODO: set back to /vyu
    // const url = `${process.env.REACT_APP_API_HOST}/vyu/api/v1/presentation-item?roomId=${roomId}&index=${index}`;
    const url = `/vyu/api/v1/presentation-item?roomId=${roomId}&index=${index}`;
    return axios.post(url, formData).catch((e) => {
        throw new Error(e.response.data.message);
    });
}

function deleteImage(roomId, fileName) {
    return axios
        // .delete(`${process.env.REACT_APP_API_HOST}/vyu/api/v1/presentation-item?filename=${fileName}&roomId=${roomId}`)
        .delete(`/vyu/api/v1/presentation-item?filename=${fileName}&roomId=${roomId}`)
        .catch((e) => {
            throw new Error(e.response.data.message);
        });
}

export default {
    getImageBlob,
    deleteImage,
    uploadImage
};
