import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import Presentation from '../Pages/Presentation';
import UserContext from './Contexts/UserContext';
import Landing from '../Pages/Landing';
import Home from '../Pages/Home';
import RoomPlaceholder from '../Pages/PresentationRoom';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';

const loggedInRoutes = () => (
    <Switch>
        <Route path='/home'>
            <Home />
        </Route>
        <Route path='/presentation/:roomId'>
            <RoomPlaceholder />
        </Route>
        <Route path='*'>
            <Redirect to='/home' />
        </Route>
    </Switch>
);

const loggedOutRoutes = () => (
    <Switch>
        <Route exact path='/welcome'>
            <Landing />
        </Route>
        <Route path='/presentation/:roomId'>
            <Landing />
        </Route>
        <Route exact path='/forgot-password'>
            <ForgotPassword />
        </Route>
        <Route exact path='/reset-password/:token'>
            <ResetPassword />
        </Route>
        <Route path='*'>
            <Redirect to='/welcome?redirect=true' />
        </Route>
    </Switch>
);

const Routes = () => (
    <UserContext.Consumer>
        {(user) => (user.userId ? loggedInRoutes() : loggedOutRoutes())}
    </UserContext.Consumer>
);

export default Routes;
