import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import UserContext from '../App/Contexts/UserContext';
import CreateRoomCard from '../components/room-card/CreateRoomCard';
import RoomCard from '../components/room-card/RoomCard';
import Typography from '@material-ui/core/Typography';
import { Container, Tooltip, makeStyles, Box } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import EventManager from '../Util/EventManager';
import AppHeader from '../components/AppHeader/AppHeader';
import UseLocalStorage from '../Util/custom-hooks/UseLocalStorage';
import RoomService from '../services/RoomService';
import MessageContext from '../App/Contexts/MessageContext';
import EntryCodeForm from '../components/entry-code/EntryCodeForm';
import DeleteRoomConfirm from '../components/DeleteRoomConfirm/DeleteRoomConfirm';

export default function Home() {
    const [externalEntryCode, setExternalEntryCode] = UseLocalStorage('external-entry-code', '');
    const [rooms, setRooms] = useState([]);
    const history = useHistory();
    const { userId } = useContext(UserContext);
    const { setMessage } = useContext(MessageContext);
    const [roomToDelete, setRoomToDelete] = useState();

    const updateRooms = () => {
        RoomService.getUserRooms(userId)
            .then(setRooms)
            .catch((e) => setMessage('Unable to get rooms' + e));
    };

    const showRoomAfterCreate = (event) => {
        if (event?.roomId) {
            history.push(`/presentation/${event.url}`);
            return;
        }
    };

    useEffect(() => {
        updateRooms();
        EventManager.reactCreateARoomEvent(updateRooms);
        EventManager.reactDeleteARoomEvent(updateRooms);
        EventManager.reactRoomEvent(updateRooms);
        EventManager.reactRoomEvent(showRoomAfterCreate);
        return () => {
            EventManager.unsetRoomEvent();
            EventManager.unsetCreateARoomEvent();
            EventManager.unsetDeleteARoomEvent();
        };
    }, []);

    useEffect(() => {
        // If coming to the home page with an external code, immediately jump them into that room
        if (externalEntryCode) {
            RoomService.entryCodePass(externalEntryCode)
                .then((room) => {
                    history.push(`/presentation/${room.url}`);
                })
                .catch((e) => {
                    console.error(e);
                    setMessage('Entry code not found: ' + externalEntryCode);
                });
        }
        // clear the external entry code upon leaving this page
        return () => setExternalEntryCode('');
    }, []);

    const useStyles = makeStyles((theme) => ({
        lock: {
            opacity: '0.8',
            color: 'crimson'
        },
        settings: {
            opacity: '0.8'
        }
    }));

    const PasswordProtected = () => {
        const styles = useStyles();
        return (
            <Tooltip title='Password Protected'>
                <LockIcon className={styles.lock} />
            </Tooltip>
        );
    };

    return (
        <Container maxWidth={false} disableGutters>
            <AppHeader />
            <DeleteRoomConfirm
                roomName={roomToDelete}
                open={!!roomToDelete}
                onConfirm={() => {
                    const roomId = rooms.find((room) => room.name === roomToDelete).roomId;
                    RoomService.deleteRoom(roomId)
                        .then(() => EventManager.emitDeleteARoomEvent())
                        .then(() => setMessage(`Room deleted: ${roomToDelete}`))
                        .catch((e) => setMessage(`Unable to delete room. ${e}`))
                        .then(() => setRoomToDelete(''));
                }}
                onCancel={() => setRoomToDelete(null)}
            />
            <Box p={3}>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <EntryCodeForm initialEntryCode={externalEntryCode} />
                    </Grid>
                    <Grid item>
                        <Typography variant='h5'>My Rooms</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <CreateRoomCard
                                    rooms={rooms.filter((room) => room.creator.userId === userId)}
                                    onClick={() => {
                                        EventManager.emitCreateARoomEvent();
                                    }}
                                />
                            </Grid>
                            {rooms
                                // only my rooms
                                .filter((room) => room.creator.userId === userId)
                                // sort by date
                                .sort((r1, r2) => {
                                    const d1 = r1.roommates.find(
                                        (mate) => mate.id.userId === userId
                                    ).lastSeen;
                                    const d2 = r2.roommates.find(
                                        (mate) => mate.id.userId === userId
                                    ).lastSeen;
                                    if (!d1 && !d2) {
                                        // if both undefined we return 0
                                        // this means they are equal
                                        return 0;
                                    } else if (!d1) {
                                        // if r1.lastSeen room is undefined we return positive
                                        // this means r1 will sort to end of array
                                        return 1;
                                    } else if (!d2) {
                                        // if r2.lastSeen room is undefined we return negative
                                        // this means r2 will sort to end of array
                                        return -1;
                                    }
                                    return new Date(d2) - new Date(d1);
                                })
                                .map((room) => (
                                    <Grid item key={room.name}>
                                        <RoomCard
                                            title={room.name}
                                            entryCode={room.entryCode}
                                            iconRight={room.password ? <PasswordProtected /> : null}
                                            menu={[
                                                {
                                                    displayText: 'Delete',
                                                    callback: () => setRoomToDelete(room.name)
                                                }
                                            ]}
                                            onClick={() =>
                                                history.push(`/presentation/${room.url}`)
                                            }
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography variant='h5'>Visited Rooms</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            {rooms
                                .filter((room) => room.creator.userId !== userId) // rooms that aren't mine
                                .filter((room) =>
                                    // Have I an allowed user of this room and have I been there at least once?
                                    room.roommates.some(
                                        (mate) => mate.id.userId === userId
                                    )
                                )
                                .sort((r1, r2) => {
                                    const d1 = r1.roommates.find(
                                        (mate) => mate.id.userId === userId
                                    ).lastSeen;
                                    const d2 = r2.roommates.find(
                                        (mate) => mate.id.userId === userId
                                    ).lastSeen;
                                    return new Date(d2) - new Date(d1);
                                })
                                .map((room) => (
                                    <Grid item key={room.name}>
                                        <RoomCard
                                            title={room.name}
                                            entryCode={room.entryCode}
                                            iconRight={room.password ? <PasswordProtected /> : null}
                                            menu={[
                                                {
                                                    displayText: 'Remove',
                                                    callback: () => setRoomToDelete(room.name)
                                                }
                                            ]}
                                            onClick={() =>
                                                history.push(`/presentation/${room.url}`)
                                            }
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
