import React, { useState, useEffect } from 'react';
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export default ({ text }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            setTimeout(() => setOpen(false), 3000);
        }
    }, [open]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        setOpen(true);
    };

    return (
        <Tooltip title='Copied!' open={open}>
            <IconButton
                aria-label='copy-to-clipboard'
                color='primary'
                disabled={!text}
                onClick={copyToClipboard}>
                <AssignmentReturnedOutlinedIcon />
            </IconButton>
        </Tooltip>
    );
};
