import axios from '../Util/AxiosIntercepted';

function getUserSubscriptionData(userId) {
    const query = `
        query ($userId: String!) {
            getUser(userId: $userId) { 
                subscription { 
                    subscriptionId 
                    subscriptionName 
                    numOfRooms 
                } 
            } 
        }
    `;

    const request = {
        query,
        variables: { userId }
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: {
                    getUser: { subscription }
                }
            }
        }) => subscription
    );
}

export default {
    getUserSubscriptionData
};
