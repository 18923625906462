import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import UserContext from '../../App/Contexts/UserContext';
import MobileUtil from '../../Util/MobileUtil';
import AppMenu from '../AppMenu/AppMenu';
import Avatar from '@material-ui/core/Avatar';

const drawerWidth = 240;
const isMobile = MobileUtil.size.isMobile();

const useStyles = makeStyles((theme) => ({
    bar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '4px 14px'
    },
    menuButton: {
        marginRight: 36,
        color: isMobile ? 'lime' : theme.palette.primary.contrastText
    },
    username: {
        fontWeight: 100,
        marginRight: '1em',
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    userInitials: {
        fontWeight: 100,
        marginRight: '1em',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    avatar: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'inherit'
        }
    },
    minwidth: {
        // This min width helps the flex rows not wrap contents at small screen widths
        minWidth: `${drawerWidth - 10}px`
    }
}));

export default function () {
    const classes = useStyles();
    const user = useContext(UserContext);
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <Grid
            container
            alignContent='space-between'
            className={`${classes.bar} ${classes.minwidth}`}>
            <Grid container item alignItems='center' xs={2} className={classes.minwidth}>
                <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    onClick={() => setMenuOpen(!menuOpen)}
                    edge='start'
                    className={classes.menuButton}>
                    <MenuIcon />
                </IconButton>
                <Typography variant='h5' noWrap>
                    <Link to='/home' style={{ color: 'unset', textDecoration: 'unset' }}>
                        VYU1
                    </Link>
                </Typography>
            </Grid>
            <Grid container item alignItems='center' justify='flex-end' xs>
                <Typography variant='h6' className={classes.username}>
                    {user.fullName}
                </Typography>
                <Typography variant='h6' className={classes.userInitials}>
                    {user.fullName
                        .split(/\s+/g)
                        .map((x) => x[0])
                        .join('')
                        .toUpperCase()}
                </Typography>

                <Avatar alt={user.fullName} className={classes.avatar} src={user.photoURL} />
            </Grid>
            <AppMenu menuOpen={menuOpen} onClose={() => setMenuOpen(!menuOpen)} />
        </Grid>
    );
}
