import React, { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import RoomService from '../../../services/RoomService';
import UserContext from '../../../App/Contexts/UserContext';
import MessageContext from '../../../App/Contexts/MessageContext';
import { red } from '@material-ui/core/colors';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ClipboardButton from '../../clipboard-button/ClipboardButton';
import EventManager from '../../../Util/EventManager';

const DeleteButton = withStyles((theme) => ({
    root: {
        '&:enabled': {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700]
            }
        }
    }
}))(Button);

const useStyles = makeStyles((theme) => ({
    maxWidth: {
        maxWidth: '100%' // Used to prevent the input boxes from growing outside the grid
    }
}));

export default function ({ showLoading }) {
    const styles = useStyles();
    const { userId } = useContext(UserContext);
    const { setMessage } = useContext(MessageContext);
    const [selectedRoom, setSelectedRoom] = useState({});
    const [selectedRoomName, setSelectedRoomName] = useState('');
    const [error, setError] = useState('');
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        setSelectedRoom(rooms.find((room) => room.name === selectedRoomName) || {});
    }, [rooms, selectedRoomName]);

    const updateRooms = () => {
        RoomService.getUserRooms(userId)
            .then(setRooms)
            .catch((e) => setMessage('Unable to get rooms'));
    };

    useEffect(() => {
        updateRooms();
        EventManager.reactRoomEvent(updateRooms);
        return () => {
            EventManager.unsetRoomEvent();
        };
    }, []);

    const deleteRoom = () => {
        showLoading(true);
        RoomService.deleteRoom(selectedRoom.roomId)
            .then(() => setSelectedRoomName(''))
            .catch((e) => setError(e.message))
            .then(() => {
                showLoading(false);
                setTimeout(() => setError(''), 4000);
            });
    };

    return (
        <Grid container spacing={2} direction='column'>
            <Grid item className={styles.maxWidth}>
                <TextField
                    size='small'
                    fullWidth
                    select
                    label='Select Room'
                    variant='outlined'
                    value={selectedRoomName || ''}
                    onChange={({ target: { value } }) => setSelectedRoomName(value)}>
                    {[
                        <MenuItem disabled value='' key=''>
                            Choose Room
                        </MenuItem>
                    ].concat(
                        rooms.map((r) => (
                            <MenuItem key={r.name} value={r.name}>
                                {r.name}
                            </MenuItem>
                        ))
                    )}
                </TextField>
            </Grid>

            <Grid item className={styles.maxWidth}>
                <TextField
                    size='small'
                    id='room_password'
                    label='Room Password'
                    type='text'
                    variant='outlined'
                    value={selectedRoom.password || '-'}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        endAdornment: <ClipboardButton text={selectedRoom.password} />
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item className={styles.maxWidth}>
                <TextField
                    size='small'
                    id='room_url'
                    label='Room URL'
                    type='text'
                    variant='outlined'
                    value={`vyu1.dev/presentation/${selectedRoom.url}`}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <ClipboardButton text={`vyu1.dev/presentation/${selectedRoom.url}`} />
                        )
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item className={styles.maxWidth}>
                <TextField
                    size='small'
                    id='room_entry_code'
                    label='Room Entry Code'
                    type='text'
                    variant='outlined'
                    value={selectedRoom.entryCode || '-'}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        endAdornment: <ClipboardButton text={selectedRoom.entryCode} />
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item className={styles.maxWidth}>
                <FormControl fullWidth>
                    <DeleteButton
                        size='small'
                        id='delete_room'
                        variant='outlined'
                        color='primary'
                        fullWidth
                        disabled={!selectedRoom.name}
                        onClick={deleteRoom}>
                        Delete Room
                    </DeleteButton>
                    <FormHelperText error id='component-error-text'>
                        {error}
                    </FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    );
}
