import axios from '../../Util/AxiosIntercepted';

export default class TrailPublisher {
    constructor(user, roommateIds, roomId, uniqueDrawingId) {
        this.user = user;
        this.roommateIds = roommateIds;
        this.points = [];
        this.TERMINAL_POINT = { terminal: true };
        this.roomId = roomId;
        // this allows showing cursor if same user logged into the same room from different browsers 🙄
        this.uniqueDrawingId = uniqueDrawingId;
    }

    addPoint(pt) {
        this.points.push(pt);
    }

    async pointsDone() {
        if (this.points.length === 0) return;
        this.points.push({ ...this.TERMINAL_POINT, t: Date.now() });
        await this.emit();
        // this.points = [];
    }

    async emit() {
        if (this.points.length === 0) {
            return;
        }
        await axios
            // .post(`${process.env.REACT_APP_EVENTS_HOST}/events/path`, {
            .post(`/events/path`, {
                user: this.user,
                roommateIds: this.roommateIds.filter((x) => x != this.user.userId),
                points: this.points,
                roomId: this.roomId,
                uniqueDrawingId: this.uniqueDrawingId
            })
            .catch(console.error).finally(() => {

            });
            this.points = [];
    }
}
