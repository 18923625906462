import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

export default ({ text }) => {
    const [opacity, setOpacity] = useState(1);
    const [transition, setTransition] = useState('');

    useEffect(() => {
        setTransition('');
        setOpacity(1);
        const t = setTimeout(() => {
            setTransition('opacity 3s, font-size 3s');
            setOpacity(0.3);
        }, 1000);
        return () => clearTimeout(t);
    }, [text]);

    return (
        <Typography
            variant='h5'
            style={{
                width: '100%',
                transition,
                opacity,
                position: 'absolute',
                bottom: '10px',
                textAlign: 'center',
                fontSize: '0.8em'
            }}>
            {text}
        </Typography>
    );
};
