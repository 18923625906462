import axios from 'axios';
import EventManager from './EventManager';

axios.interceptors.request.use((req) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.access_token) {
        req.headers.authorization = `Bearer ${user.access_token}`;
    }
    return req;
});

// Only temporarily excluding the /v1/user API until auth is not required
const noRedirect = ['/vyu/authenticate/login', '/vyu/api/v1/user'];

axios.interceptors.response.use(
    (res) => {
        if (res?.data?.errors?.length) {
            // show gql errors
            console.log(`\n\nGQL Error:\n${res.data.errors[0]?.message}\n\n`);
            throw new Error(res.data.errors[0]?.message);
        }
        return res;
    },
    (err) => {
        if (err.response.status === 401 && !noRedirect.includes(err?.response?.config?.url)) {
            EventManager.emitKillUser(); // logs out the user
            setTimeout(() => window.location.replace('/'), 200); // force browser redirect
        }
        console.log('error seen in axios intercepted', JSON.stringify(err.response, null, 2));
        throw err;
    }
);

export default axios;
