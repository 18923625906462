import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LoadingAnimation from '../loading-animation/LoadingAnimation';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import UserService from '../../services/UserService';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

function invalidEmail(email) {
    if (!email) return;
    if (!/\w+@\w+\.\w+/.test(email)) {
        return 'A vaild email is required';
    }
}

function invalidPassword(password) {
    if (!password) return;
    if (password.length < 6) {
        return 'Passwords must be at least 6 characters';
    }
    // const lowercase = /[a-z]/;
    // const uppercase = /[A-Z]/;
    // const numbers = /[0-9]/;
    const allowedSpecials = [
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '_',
        '+',
        '-',
        '=',
        ',',
        '?',
        '.'
    ];
    // const specialRE = new RegExp(`[${allowedSpecials.map((c) => `\\${c}`).join('')}]`);
    const badcharacters = new RegExp(
        `[^a-zA-Z0-9${allowedSpecials.map((c) => `\\${c}`).join('')}]`
    );
    if (
        !(
            // lowercase.test(password) &&
            // uppercase.test(password) &&
            // numbers.test(password) &&
            // specialRE.test(password)
            (password.length > 5)
        )
    ) {
        // return 'Must have one of each: lowercase letter, uppercase letter, number, special character';
        return 'Password must have at least 6 characters';
    }
    if (badcharacters.test(password)) {
        return `Only these special characters are valid: ${allowedSpecials.join('')}`;
    }
}

function readyForSubmit(userobject) {
    return (
        Object.values(userobject).every(({ value }) => value) &&
        Object.values(userobject).every(({ error }) => !error)
    );
}

export default function (props) {
    const [loading, setLoading] = useState(false);
    const [existing, setExisting] = useState({ email: { value: '' }, password: { value: '' } });
    const [newUser, setNewUser] = useState({
        email: { value: '' },
        password: { value: '' },
        verify: { value: '' },
        first: { value: '' },
        last: { value: '' }
    });
    const [tab, setTab] = useState(props.tab);
    const [submitError, setSubmitError] = useState('');

    useEffect(() => setTab(props.tab), [props.tab]);
    useEffect(() => setSubmitError(''), [existing, newUser]);

    const closeDialog = () => {
        props.onClose();
        setLoading(false);
    };

    function nativeLogin(e) {
        e.preventDefault();
        setLoading(true);
        UserService.login(existing.email.value, existing.password.value)
            .catch((err) => setSubmitError(err.message))
            .then(() => setLoading(false));
    }

    function createAccount(e) {
        e.preventDefault();
        setLoading(true);
        const data = {
            email: newUser.email.value,
            password: newUser.password.value,
            first: newUser.first.value,
            last: newUser.last.value
        };
        UserService.createAccount(data)
            .catch((error) => setSubmitError(error.response.data.message))
            .then((data) => {
                setLoading(false);
            });
    }

    return (
        <Dialog open={props.open} onClose={closeDialog}  maxwidth='sm'>
            <Grid container alignItems='stretch' alignContent='center' direction='column'>
                <AppBar position='static'>
                    <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
                        <Tab label='Existing User' onClick={() => setSubmitError('')} />
                        <Tab label='New User' onClick={() => setSubmitError('')} />
                    </Tabs>
                </AppBar>
            </Grid>
            <Accordion expanded={`panel${tab + 1}` === 'panel1'}>
                <AccordionSummary style={{ display: 'none' }} />
                <AccordionDetails>
                    <form style={{ width: '100%' }} onSubmit={nativeLogin} autoComplete='on'>
                        <Grid container spacing={1} alignItems='stretch' direction='column'>
                            <Grid item>
                                <TextField
                                    required
                                    autoComplete='email'
                                    autoFocus
                                    id='email_existing'
                                    label='Email Address'
                                    variant='outlined'
                                    value={existing.email.value}
                                    onChange={({ target: { value } }) =>
                                        setExisting({ ...existing, email: { value } })
                                    }
                                    onBlur={() =>
                                        setExisting({
                                            ...existing,
                                            email: {
                                                ...existing.email,
                                                error: invalidEmail(existing.email.value)
                                            }
                                        })
                                    }
                                    helperText={existing.email.error}
                                    error={!!existing.email.error}
                                    
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    autoComplete='current-password'
                                    required
                                    id='password'
                                    label='Password'
                                    type='password'
                                    variant='outlined'
                                    value={existing.password.value}
                                    onChange={({ target: { value } }) =>
                                        setExisting({ ...existing, password: { value } })
                                    }
                                    onBlur={() =>
                                        setExisting({
                                            ...existing,
                                            password: {
                                                ...existing.password
                                            }
                                        })
                                    }
                                    helperText={existing.password.error}
                                    error={!!existing.password.error}
                                    
                                />
                            </Grid>
                            <Grid item>
                                <FormControl >
                                    <Button
                                        disabled={!readyForSubmit(existing)}
                                        // onClick={nativeLogin}
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        >
                                        Login
                                    </Button>
                                    <FormHelperText error id='component-error-text'>
                                        {submitError}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item><Link to='/forgot-password' style={{ color: 'unset', textDecoration: 'unset' }}>Forgot your password?</Link></Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={`panel${tab + 1}` === 'panel2'}>
                <AccordionSummary style={{ display: 'none' }} />
                <AccordionDetails>
                    <form style={{ width: '100%' }} onSubmit={createAccount} autoComplete='on'>
                        <Grid container spacing={1} alignItems='stretch' direction='column'>
                            <Grid item>
                                <TextField
                                    autoComplete='email'
                                    autoFocus
                                    required
                                    id='email_create'
                                    label='Email Address'
                                    variant='outlined'
                                    value={newUser.email.value}
                                    onChange={({ target: { value } }) =>
                                        setNewUser({ ...newUser, email: { value } })
                                    }
                                    onBlur={() =>
                                        setNewUser({
                                            ...newUser,
                                            email: {
                                                ...newUser.email,
                                                error: invalidEmail(newUser.email.value)
                                            }
                                        })
                                    }
                                    helperText={newUser.email.error}
                                    error={!!newUser.email.error}
                                    
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    autoComplete='new-password'
                                    id='password_create'
                                    label='Password'
                                    type='password'
                                    variant='outlined'
                                    value={newUser.password.value}
                                    onChange={({ target: { value } }) =>
                                        setNewUser({ ...newUser, password: { value } })
                                    }
                                    onBlur={() =>
                                        setNewUser({
                                            ...newUser,
                                            password: {
                                                ...newUser.password,
                                                error: invalidPassword(newUser.password.value)
                                            }
                                        })
                                    }
                                    helperText={newUser.password.error}
                                    error={!!newUser.password.error}
                                    
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    autoComplete='new-password'
                                    id='password_create_verify'
                                    label='Verify Password'
                                    type='password'
                                    variant='outlined'
                                    value={newUser.verify.value}
                                    onChange={({ target: { value } }) =>
                                        setNewUser({ ...newUser, verify: { value } })
                                    }
                                    onBlur={() =>
                                        setNewUser({
                                            ...newUser,
                                            verify: {
                                                ...newUser.verify,
                                                error:
                                                    newUser.verify.value &&
                                                    newUser.verify.value !== newUser.password.value
                                                        ? `Passwords don't match`
                                                        : ''
                                            }
                                        })
                                    }
                                    helperText={newUser.verify.error}
                                    error={!!newUser.verify.error}
                                    
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant='body1'>User Details</Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    autoComplete='given-name'
                                    id='first'
                                    label='First Name'
                                    variant='outlined'
                                    value={newUser.first.value}
                                    onChange={({ target: { value } }) =>
                                        setNewUser({ ...newUser, first: { value } })
                                    }
                                    
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    autoComplete='family-name'
                                    id='last'
                                    label='Last Name'
                                    variant='outlined'
                                    value={newUser.last.value}
                                    onChange={({ target: { value } }) =>
                                        setNewUser({ ...newUser, last: { value } })
                                    }
                                    
                                />
                            </Grid>
                            <Grid item>
                                <FormControl >
                                    <Button
                                        disabled={!readyForSubmit(newUser)}
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        >
                                        Join
                                    </Button>
                                    <FormHelperText error id='component-error-text'>
                                        {submitError}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>
            {props.externalEntryCode || props.directURL ? (
                <Box py={2}>
                    <Grid container justify='center'>
                        {`panel${tab + 1}` === 'panel1' ? (
                            <i>Please log-in to proceed to your room</i>
                        ) : (
                            <i>Please create an account to proceed to your room</i>
                        )}
                    </Grid>
                </Box>
            ) : null}
            {loading ? <LoadingAnimation /> : null}
        </Dialog>
    );
}
