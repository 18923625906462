import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid, Box, makeStyles } from '@material-ui/core';
import { ListView } from '@progress/kendo-react-listview';
import { Card, CardTitle, CardSubtitle } from '@progress/kendo-react-layout';
import { TextArea } from '@progress/kendo-react-inputs';
import ChatService from '../../services/ChatService';
import EventManager from '../../Util/EventManager';

const FormTextArea = ({ value, onChange, handleOnEnter }) => {
    return (
        <TextArea
            id='chat-text-area'
            style={{ width: '100%', outline: 'none' }}
            value={value}
            onChange={onChange}
            onKeyPress={handleOnEnter}
        />
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& textarea': {
            resize: 'none !important'
        }
    }
}));

// Safari position:sticky doesn't treat siblings the same as Chrome.
// This hack allows the sibling elements in the chat to not overlap
// const safariPadding = MobileUtil.browser.isSafari() ? { paddingBottom: '65px' } : {};
const safariPadding = {};

export default function ({ containerHeight = 0, roomId = null }) {
    const scrollRef = useRef();
    const textAreaRef = useRef();
    const classes = useStyles();
    const [thisChat, setThisChat] = useState([]);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [altPressed, setAltPressed] = useState(false);

    function initChat(roomId) {
        if (roomId == null) {
            return;
        }
        return ChatService.getChatMessagesByRoomId(roomId)
            .then((chatResponse) => {
                if (chatResponse) {
                    const chats = chatResponse.map((response) => {
                        const time = new Date(Date.parse(response.chatMessageDate));
                        return {
                            name: `${response.sender.firstName} ${response.sender.lastName}`,
                            message: response.text,
                            time: time
                        };
                    });
                    const chatsReverse = chats.reverse();
                    setThisChat(chatsReverse);
                }
            })
            .catch((e) => console.log(e))
            .then(() => setTimeout(scrollToBottom, 200));
    }

    function onChange(e) {
        setTextAreaValue(e.value);
    }

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        }
    }, [textAreaValue]);

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.parentNode.scrollTop = scrollRef.current.parentNode.scrollHeight;
        }
    };

    const postChatMessage = async () => {
        if (!textAreaValue) {
            return;
        }
        await ChatService.createChatMessage(
            roomId,
            textAreaValue,
            new Date().toISOString()
        ).catch((e) => console.log(e));
        setTextAreaValue('');
    };

    useEffect(() => {
        initChat(roomId);
        EventManager.reactChatEvent(() => {
            initChat(roomId);
        });
        textAreaRef.current = document.querySelector('#chat-text-area');
        return () => {
            EventManager.unsetChatEvent();
        };
    }, []);

    // When the user changes the height of the container, re-scroll to the bottom
    useEffect(scrollToBottom, [containerHeight]);

    const MyItemRender = ({ dataItem: { time, message, name } }) => {
        return (
            <Card style={{ borderColor: 'rgba(0,0,0,0)' }}>
                <div className='chat-cell'>
                    <CardTitle style={{ fontSize: 16 }}>
                        <b>{name} - </b>
                        {time.toLocaleString()}
                    </CardTitle>
                    <CardSubtitle style={{ fontSize: 14, whiteSpace: 'pre-wrap' }}>
                        {message}
                    </CardSubtitle>
                    <hr />
                </div>
            </Card>
        );
    };

    const handleOnALTUp = (e) => {
        if (['Meta', 'Control', 'Alt'].includes(e.key)) {
            setAltPressed(false);
        }
    };

    const handleOnALTDown = (e) => {
        if (['Meta', 'Control', 'Alt'].includes(e.key)) {
            setAltPressed(true);
        }
    };

    const handleOnEnter = (e) => {
        if (!altPressed && e.key === 'Enter') {
            e.preventDefault();
            postChatMessage();
        } else if (e.key === 'Enter') {
            setTextAreaValue(textAreaValue + '\n');
        }
    };

    // TODO: Get chats relevant to this room and user to render
    return (
        /* Height 100% required to make chat text & button sticky on safari */
        <Box px={2} pt={2} ref={scrollRef} height='100%'>
            <ListView
                id={'chat-list'}
                data={thisChat ? thisChat : []}
                item={MyItemRender}
                style={{ borderColor: 'rgba(0,0,0,0)', ...safariPadding }}
            />
            <Grid
                container
                direction='row'
                justify='center'
                alignItems='stretch'
                spacing={2}
                className={classes.root}
                style={{
                    position: 'sticky',
                    bottom: 0,
                    background: 'white'
                }}
                onKeyDown={handleOnALTDown}
                onKeyUp={handleOnALTUp}>
                <Grid item xs={8} sm={9} md={10}>
                    <FormTextArea
                        value={textAreaValue}
                        onChange={onChange}
                        handleOnEnter={handleOnEnter}
                        ref={textAreaRef}
                    />
                </Grid>
                <Grid item xs={4} sm={3} md={2}>
                    <Button
                        fullWidth
                        color='primary'
                        variant='outlined'
                        style={{ height: '100%' }}
                        onClick={postChatMessage}>
                        Send
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
