import React from 'react';
import UserContext from './UserContext';
import MessageContext from './MessageContext';

function Contexts(props) {
    return (
        <UserContext.Provider value={props.userContext}>
            <MessageContext.Provider value={props.messageContext}>
                {props.children}
            </MessageContext.Provider>
        </UserContext.Provider>
    );
}

export default Contexts;
