import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Hidden, Box, makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
    root: {
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    }
}));

export default function ({ actions }) {
    const styles = useStyles();

    return (
        <Grid
            style={{
                position: 'absolute',
                bottom: '1vh',
                right: '2vw',
                zIndex: '1199',
                width: 'auto'
            }}
            spacing={2}
            container
            justify='flex-end'>
            {actions.map((action, i) => (
                <Grid item key={`fab_${i}`}>
                    <Fab
                        size='small'
                        variant='extended'
                        className={styles.root}
                        onClick={action.onClick}>
                        {action.icon}
                        <Hidden smDown>
                            <Box px={1}>{action.text}</Box>
                        </Hidden>
                    </Fab>
                </Grid>
            ))}
        </Grid>
    );
}
