import React, { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Container, Hidden, Drawer } from '@material-ui/core';
import RoomService from '../services/RoomService';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';
import FullscreenExitSharpIcon from '@material-ui/icons/FullscreenExitSharp';
import Chat from '../components/chat/Chat';
import FloatingHUD from '../components/presentation-components/FloatingHUD';
import PeopleDrawer from '../components/presentation-components/PeopleDrawer';
import PresentationDrawer from '../components/presentation-components/PresentationDrawer';
import TextFadeOut from '../components/presentation-components/TextFadeOut';
import Vyur from '../components/presentation-components/Vyur';
import ImageService from '../services/ImageService';
import MobileUtil from '../Util/MobileUtil';
import EventManager from '../Util/EventManager';
import MessageContext from '../App/Contexts/MessageContext';
import UserContext from '../App/Contexts/UserContext';
import { Splitter } from '@progress/kendo-react-layout';
import AppHeader from '../components/AppHeader/AppHeader';
import '../components/splitter.css';

const isMobile = MobileUtil.size.isMobile();

const placeholderImages = {
    loading: 'https://i.imgur.com/FnSf0dL.jpg',
    upload: 'https://i.imgur.com/BdbjnMF.jpg',
    selectImage: 'https://i.imgur.com/jw1gtE8.png'
};

// function handlePaneChanges(beforePanes, afterPanes, mobileSideCallback) {
//     if (afterPanes.some((p) => ['LEFT', 'RIGHT'].some((name) => name === p.name))) {
//         const opening = afterPanes.find((p) => !p.collapsed);
//         if (isMobile) {
//             mobileSideCallback(opening.name);
//             return beforePanes; // return original state. never open side panes in mobile
//         }
//     }

//     return afterPanes.map((pane, i) => {
//         if (!pane.collapsible) {
//             return pane;
//         }

//         const currSize = +pane.size.replace(/\D.*$/, '');
//         const deflateSize = +pane.deflateBelow.replace(/\D.*$/, '');
//         if (currSize < deflateSize) {
//             return {
//                 ...pane,
//                 size: pane.inflateTo,
//                 collapsed: true
//             };
//         }
//         return pane;
//     });
// }

export default function PresentationRoom() {
    const history = useHistory();
    const { setMessage } = useContext(MessageContext);
    const user = useContext(UserContext);
    const [thisRoom, setThisRoom] = useState({});
    const [showPeopleDrawer, setShowPeopleDrawer] = useState(false);
    const [showPresentationDrawer, setShowPresentationDrawer] = useState(
        !MobileUtil.size.isMobile()
    );
    const [fullscreen, setFullscreen] = useState(false);
    const [splitterMaxHeight, setSplitterMaxHeight] = useState(window.innerHeight - 62);
    const [currentImg, setCurrentImg] = useState(document.createElement('img'));
    const [maxIndex, setMaxIndex] = useState(0);

    const [slidesMap, setSlidesMap] = useState({});
    const [sortAsc, setSortAsc] = useState(true);
    // Todo: keep cursor in a db and set it on demand
    const [cursor, setCursor] = useState('url(/images/orange-arrow.png) 1 1, auto');
    const [roommateIds, setroommateIds] = useState([]);
    const [clicker, setClicker] = useState();

    const [isLeftMobileDrawerOpen, setIsLeftMobileDrawerOpen] = useState(false);
    const [isRightMobileDrawerOpen, setIsRightMobileDrawerOpen] = useState(false);

    // Kinda like a cache so the same images aren't loaded over the network repeatedly
    const loadedImagesRef = useRef([]);
    const [triggerNewSlidesMap, setTriggerNewSlidesMap] = useState(Math.random());
    const [visibleIndices, setVisibleIndices] = useState([]);

    const [horizontalPanes, setHorizontalPanes] = useState([
        {
            size: MobileUtil.size.isMobile() ? '60%' : '20%',
            deflateBelow: '10%',
            inflateTo: '15%',
            collapsible: true,
            scrollable: true,
            collapsed: MobileUtil.size.isMobile(), // Both side panels are open when not-mobile
            name: 'LEFT'
        },
        { min: '30%', scrollable: false, collapsible: false, name: 'MIDDLE' },
        {
            size: MobileUtil.size.isMobile() ? '60%' : '20%',
            deflateBelow: '10%',
            inflateTo: '15%',
            collapsible: true,
            scrollable: true,
            collapsed: MobileUtil.size.isMobile(), // Both side panels are open when not-mobile
            name: 'RIGHT'
        }
    ]);
    const [verticalPanes, setVerticalPanes] = useState([
        { scrollable: false, name: 'vyuer-top-pane' },
        {
            size: '20%',
            deflateBelow: '10%',
            inflateTo: '15%',
            collapsible: true,
            collapsed: false,
            scrollable: true,
            name: 'BOTTOM'
        }
    ]);

    // Overlays come with the splitter component and cover up other elements from being clicked
    [
        ...document.querySelectorAll('div.k-pane.k-pane-flex > div.k-splitter-overlay.k-overlay')
    ].forEach((overlay) => (overlay.style.display = 'none'));

    function initRoom() {
        const url = /presentation\/(\w+)/g.exec(window.location.pathname)?.[1];
        RoomService.roomLogin(url)
            .then((room) => {
                setThisRoom(room);
                const idsOfOtherUsersForThisRoom = room.roommates
                    .filter((mate) => mate.id.userId != user.userId)
                    .map((mate) => mate.id.userId);
                setroommateIds(idsOfOtherUsersForThisRoom);
                setItems(room.presentationItems, room.roomId);
            })
            .catch((e) => {
                console.log(e);
                if (e.message.includes('not found')) {
                    setMessage('Room not found');
                    history.push(`/home`);
                } else {
                    setMessage(e.message);
                }
            });
    }

    useEffect(() => {
        initRoom();
    }, []);

    useEffect(() => {
        const { roomId } = thisRoom;
        if (roomId && user.userId) {
            RoomService.heartbeat(roomId, user.userId); // send one now, then send one every so often until leaving the room
            const interval = setInterval(() => RoomService.heartbeat(roomId, user.userId), 20000); // 20 seconds

            EventManager.reactConnectEvent((data) => {
                if (
                    data.sender.firstName &&
                    data.payload.roomId === thisRoom.roomId &&
                    data.sender.userId !== user.userId
                ) {
                    setMessage(`${data.sender.firstName} has ${data.eventType === 'USER_CONNECT' ? 'joined' : 'left'} the room`);
                }
                const url = /presentation\/(\w+)/g.exec(window.location.pathname)?.[1];
                RoomService.getRoomByRoomUrl(url)
                    .then((room) => {
                        setThisRoom(room);
                        const idsOfOtherUsersForThisRoom = room.roommates
                            .filter((mate) => mate.id.userId !== user.userId)
                            .map((mate) => mate.id.userId);
                        setroommateIds(idsOfOtherUsersForThisRoom);
                        setItems(room.presentationItems, room.roomId);
                    })
                    .catch(({ message }) => setMessage(message));
            });

            return () => {
                clearInterval(interval);
                EventManager.unsetConnectEvent();
            };
        }
    }, [thisRoom]);

    const handleSideDrawersInMobile = (name) => {
        switch (name) {
            case 'LEFT':
                setIsLeftMobileDrawerOpen(true);
                break;
            case 'MIDDLE':
            case 'RIGHT':
                setIsRightMobileDrawerOpen(true);
                break;
            default:
                return;
        }
    };

    // const onHorizontalChange = (event) =>
    //     setHorizontalPanes(
    //         handlePaneChanges(horizontalPanes, event.newState, handleSideDrawersInMobile)
    //     );
    const onHorizontalChange = (event) => {
        setHorizontalPanes(event.newState);
    };

    // const onVerticalChange = (event) =>
    //     setVerticalPanes(
    //         handlePaneChanges(verticalPanes, event.newState, handleSideDrawersInMobile)
    //     );
    const onVerticalChange = (event) => {
        setVerticalPanes(event.newState);
    };

    React.useEffect(() => {
        function handleResize() {
            setSplitterMaxHeight(window.innerHeight - 62);
            setHorizontalPanes(horizontalPanes);
            setVerticalPanes(verticalPanes);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const updateRoom = (event) => {
        if (event.eventType == 'INDEX_CHANGE') {
            console.log(event.sender);
            setClicker(`${event.sender.firstName} ${event.sender.lastName}`);
        } else if (event.eventType === 'DELETE' && event.payload.roomId === thisRoom.roomId) {
            setMessage('Room was deleted by the owner: ' + event.payload.name);
            history.push(`/home`);
            return;
        }
        const url = /presentation\/(\w+)/g.exec(window.location.pathname)?.[1];
        RoomService.getPresentationForRoomUrl(url)
            .then(({ presentationItems, roomId }) => setItems(presentationItems, roomId))
            .catch((e) => {
                console.log(e);
                setMessage(e.message);
            });
    };

    useEffect(() => {
        EventManager.reactRoomEvent(updateRoom);
        return () => {
            EventManager.unsetRoomEvent();
        };
    }, [thisRoom]);

    const setItems = (presentationItems = [], roomId) => {
        setMaxIndex(
            (presentationItems || []).map(({ index }) => +index).reduce((a, i) => Math.max(a, i), 0)
        );

        // Don't request data from backend for images that are not visible or have been pulled already
        const imagesToShow = (presentationItems || []).filter(({ visible }) => visible);
        setVisibleIndices(imagesToShow.map(({ index }) => index));

        // Download images that both: should be visible and are not already loaded
        const imagesThatNeedToBeDownloaded = (presentationItems || [])
            .filter(({ index }) => imagesToShow.map((room) => room.index).includes(index))
            .filter(
                ({ index }) =>
                    !loadedImagesRef.current.find((loadedImage) => loadedImage.index === index)
            );

        imagesThatNeedToBeDownloaded.forEach((item) => {
            ImageService.getImageBlob(roomId, item.itemName)
                .then((blob) => {
                    const burl = window.URL.createObjectURL(blob);
                    const downloadedItem = { ...item, burl };
                    loadedImagesRef.current = loadedImagesRef.current.concat(downloadedItem);
                    setTriggerNewSlidesMap(Math.random());
                })
                .catch((e) => setMessage(e.message));
        });

        // For every image that is already downloaded, if it is no longer selected or it
        // has been deleted, update those states
        loadedImagesRef.current = loadedImagesRef.current.map((currentImage) => {
            const match = presentationItems.find((item) => item.index === currentImage.index);
            if (match) {
                // this fixes bug where deleted images 'come back' if you upload new images after deleting
                return { ...currentImage, ...match };
            }
            return currentImage;
        });

        // after images ref is updated, cause a render
        setTriggerNewSlidesMap(Math.random());
    };

    const updateImage = (img) => {
        if (currentImg.src !== img.src) {
            setCurrentImg(img);
        }
    };

    useEffect(() => {
        const newSlidesMap = loadedImagesRef.current
            // this filter takes away images that are erased
            .filter(({ visible }) => visible)
            .reduce((map, loadedImage) => {
                map[loadedImage.index] = loadedImage;
                return map;
            }, {});
        setSlidesMap(newSlidesMap);
    }, [triggerNewSlidesMap]);

    useEffect(() => {
        // On page load, initialize the image shown in the Vyur to the top image (lowest index)
        const img = document.createElement('img');
        if (Object.values(slidesMap).length) {
            const selectedItems = Object.values(slidesMap)
                .filter(({ selected }) => selected)
                .map((s) => s.index);
            const progress = getPresentationLoadedProgress();
            if (progress.loadedImages < progress.totalImages) {
                img.src = placeholderImages.loading; // "Grabbing your presentation..."
                updateImage(img);
            } else if (selectedItems.length) {
                const burl = Object.values(slidesMap).filter((i) => i.index === selectedItems[0])[0].burl;
                if (burl) {
                    img.src = burl;
                    updateImage(img);
                }
            } else {
                const img = document.createElement('img');
                img.src = placeholderImages.selectImage; // "Select an image"
                updateImage(img);
            }
        } else {
            const img = document.createElement('img');
            img.src = placeholderImages.upload; // "Upload an image to get started"
            updateImage(img);
        }
    }, [slidesMap]);

    function getSlides(listOfSlides) {
        return listOfSlides.sort((a, b) => (sortAsc ? a.index - b.index : b.index - a.index));
    }

    const getPresentationLoadedProgress = () => {
        const totalImages = visibleIndices.length;
        const loadedImages = visibleIndices.filter((index) => !!slidesMap[index]).length;
        return { totalImages, loadedImages };
    };

    return (
        <Container maxWidth={false} disableGutters style={{ height: '100%' }}>
            {!thisRoom?.name ? (
                <div> LOADING .... </div>
            ) : fullscreen ? (
                <div>
                    <Vyur
                        cursor={cursor}
                        img={currentImg}
                        roommateIds={roommateIds}
                        roomId={thisRoom.roomId}
                        fullscreen
                    />
                    <FloatingHUD
                        actions={[
                            {
                                text: 'Full Screen',
                                icon: fullscreen ? (
                                    <FullscreenExitSharpIcon />
                                ) : (
                                    <FullscreenRoundedIcon />
                                ),
                                onClick: () => setFullscreen(!fullscreen)
                            }
                        ]}
                    />
                </div>
            ) : (
                <>
                    <AppHeader />
                    {isMobile ? (
                        <>
                            <Drawer
                                anchor='left'
                                open={isLeftMobileDrawerOpen}
                                onClose={() => setIsLeftMobileDrawerOpen(false)}>
                                <Box minWidth='80vw'>
                                    <PeopleDrawer
                                        roomData={thisRoom}
                                        open={showPeopleDrawer}
                                        onClose={() => setShowPeopleDrawer(false)}
                                    />
                                </Box>
                            </Drawer>
                            <Drawer
                                anchor='right'
                                open={isRightMobileDrawerOpen}
                                onClose={() => setIsRightMobileDrawerOpen(false)}>
                                <Box minWidth='80vw'>
                                    <PresentationDrawer
                                        open={showPresentationDrawer}
                                        onClose={() => setIsRightMobileDrawerOpen(false)}
                                        imgClick={updateImage}
                                        onSort={() => setSortAsc(!sortAsc)}
                                        slides={getSlides(Object.values(slidesMap))}
                                        roomId={thisRoom.roomId}
                                        maxIndex={maxIndex}
                                        onDelete={(slide) => {
                                            ImageService.deleteImage(
                                                thisRoom.roomId,
                                                slide.itemName
                                            )
                                                .then(updateRoom)
                                                .catch((e) => setMessage(e.message));
                                        }}
                                        adminIds={thisRoom.roommates
                                            .filter((r) => r.role === 'ADMIN')
                                            .map((r) => r.id.userId)}
                                        roomOwnerId={thisRoom.creator.userId}
                                        {...getPresentationLoadedProgress()}
                                    />
                                </Box>
                            </Drawer>
                        </>
                    ) : null}
                    <Splitter
                        style={{
                            height: `100%`,
                            maxHeight: `${splitterMaxHeight}px`
                        }}
                        panes={horizontalPanes}
                        onChange={onHorizontalChange}>
                        <PeopleDrawer
                            roomData={thisRoom}
                            open={showPeopleDrawer}
                            onClose={() => setShowPeopleDrawer(false)}
                        />

                        <Splitter
                            panes={verticalPanes}
                            orientation={'vertical'}
                            onChange={onVerticalChange}>
                            <Box
                                p={2}
                                maxHeight='100%'
                                style={{
                                    height: '100%'
                                }}>
                                <div
                                    style={{
                                        height: '100%',
                                        maxHeight: 'calc(100% - 40px)',
                                        flexDirection: 'column',
                                        rowGap: '10px',
                                    }}>
                                    <div>
                                        <Grid container direction='row'>
                                            <Grid item xs={7} sm={6}>
                                                <Typography
                                                    variant='h5'
                                                    style={{ fontWeight: 'bolder' }}>
                                                    {thisRoom.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5} sm={6}>
                                                <Typography
                                                    variant='h5'
                                                    align='right'>{`Entry Code: ${
                                                    thisRoom.entryCode || 'No Entry Code'
                                                }`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </div   >
                                    <Vyur
                                        cursor={cursor}
                                        img={currentImg}
                                        roommateIds={roommateIds}
                                        roomId={thisRoom.roomId}
                                    />
                                    <FloatingHUD
                                        actions={[
                                            {
                                                text: 'Full Screen',
                                                icon: fullscreen ? (
                                                    <FullscreenExitSharpIcon />
                                                ) : (
                                                    <FullscreenRoundedIcon />
                                                ),
                                                onClick: () => setFullscreen(!fullscreen)
                                            }
                                        ]}
                                    />
                                    <TextFadeOut text={clicker} />
                                </div>
                            </Box>
                            <Chat
                                containerHeight={verticalPanes[1].size}
                                roomId={thisRoom ? thisRoom.roomId : null}
                            />
                        </Splitter>
                        <div>
                            <PresentationDrawer
                                open={showPresentationDrawer}
                                onClose={() => setShowPresentationDrawer(false)}
                                imgClick={updateImage}
                                onSort={() => setSortAsc(!sortAsc)}
                                slides={getSlides(Object.values(slidesMap))}
                                roomId={thisRoom.roomId}
                                maxIndex={maxIndex}
                                onDelete={(slide) => {
                                    ImageService.deleteImage(thisRoom.roomId, slide.itemName)
                                        .then(updateRoom)
                                        .catch((e) => setMessage(e.message));
                                }}
                                adminIds={thisRoom.roommates
                                    .filter((r) => r.role === 'ADMIN')
                                    .map((r) => r.id.userId)}
                                roomOwnerId={thisRoom.creator.userId}
                                {...getPresentationLoadedProgress()}
                            />
                        </div>
                    </Splitter>
                </>
            )}
        </Container>
    );
}
