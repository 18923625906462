import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LoadingAnimation from '../loading-animation/LoadingAnimation';
import UserService from '../../services/UserService';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

function invalidPassword(password) {
    if (!password) return;
    if (password.length < 6) {
        return 'Passwords must be at least 6 characters';
    }
    // const lowercase = /[a-z]/;
    // const uppercase = /[A-Z]/;
    // const numbers = /[0-9]/;
    const allowedSpecials = [
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '_',
        '+',
        '-',
        '=',
        ',',
        '?',
        '.'
    ];
    // const specialRE = new RegExp(`[${allowedSpecials.map((c) => `\\${c}`).join('')}]`);
    const badcharacters = new RegExp(
        `[^a-zA-Z0-9${allowedSpecials.map((c) => `\\${c}`).join('')}]`
    );
    if (
        !(
            // lowercase.test(password) &&
            // uppercase.test(password) &&
            // numbers.test(password) &&
            // specialRE.test(password)
            (password.length > 5)
        )
    ) {
        // return 'Must have one of each: lowercase letter, uppercase letter, number, special character';
        return 'Password must have at least 6 characters';
    }
    if (badcharacters.test(password)) {
        return `Only these special characters are valid: ${allowedSpecials.join('')}`;
    }
}

function confirmPassword(password1, password2) {
    if (password1 !== password2) return "The password fields do not match";
}

function readyForSubmit(formData) {
    return (
        Object.values(formData).every(({ value }) => value) &&
        Object.values(formData).every(({ error }) => !error)
    );
}

// token prop is the reset password token that has already been verified
export default function ({ token }) {
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [formData, setFormData] = useState({ 
        password1: { value: '', error: null },
        password2: { value: '', error: null }
    });

    function submit(e) {
        e.preventDefault();
        setLoading(true);
        try {
            UserService.updatePassword(formData.password1.value, token)
            .catch((error) => setSubmitError(error.response.data.message))
            .then((data) => {
                setLoading(false);
            });
        } catch (err) {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <form style={{ width: '100%' }} onSubmit={submit} autoComplete='on'>
                <Grid container spacing={1} alignItems='stretch' direction='column'>
                    <Grid item>
                        <TextField
                            required
                            autoComplete='current-password'
                            autoFocus
                            id='password1'
                            label='Password'
                            variant='outlined'
                            value={formData.password1.value}
                            onChange={({ target: { value } }) =>
                                setFormData({ ...formData, password1: { value } })
                            }
                            onBlur={() =>
                                setFormData({
                                    ...formData,
                                    password1: {
                                        ...formData.password1,
                                        error: invalidPassword(formData.password1.value)
                                    }
                                })
                            }
                            helperText={formData.password1.error}
                            error={!!formData.password1.error}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            required
                            autoComplete='current-password'
                            id='password2'
                            label='Password'
                            variant='outlined'
                            value={formData.password2.value}
                            onChange={({ target: { value } }) =>
                                setFormData({ ...formData, password2: { value } })
                            }
                            onBlur={() =>
                                setFormData({
                                    ...formData,
                                    password2: {
                                        ...formData.password2,
                                        error: confirmPassword(formData.password1.value, formData.password2.value)
                                    }
                                })
                            }
                            helperText={formData.password2.error}
                            error={!!formData.password2.error}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <Button
                                disabled={!readyForSubmit(formData)}
                                variant='contained'
                                color='primary'
                                type='submit'
                                fullWidth>
                                Save Password
                            </Button>
                            <Button component={Link} to="/" color="secondary" variant="contained">
                                Cancel
                            </Button>
                            <FormHelperText error id='component-error-text'>
                                {submitError}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
            {loading ? <LoadingAnimation /> : null}
        </Fragment>
    );
}
