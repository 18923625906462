import React from 'react';

function LinkSVG() {
    return (
        <svg viewBox='0 0 172 172'>
            <g transform='translate(12.04,12.04) scale(0.86,0.86)'>
                <g>
                    <path d='M0,172v-172h172v172z' fill='none' stroke='none'></path>
                    <g fill='none' stroke='black' strokeWidth='5'>
                        <path d='M129.82692,1.03365c-10.77584,0.51683 -21.13822,5.21996 -29.14904,13.23077l-31.42308,31.42308c4.9357,-4.96154 23.25721,-1.55048 27.70192,2.89423l19.01923,-19.01923c4.21214,-4.21214 9.48378,-6.79628 14.88462,-7.02885c3.66947,-0.18089 8.86358,0.59435 13.4375,5.16827c4.26382,4.26382 5.16827,9.22536 5.16827,12.61058c0,5.65926 -2.58413,11.29267 -7.02885,15.71154l-33.07692,33.28365c-8.32091,8.32092 -20.98317,8.99279 -28.32212,1.65385c-4.18629,-4.18629 -11.08594,-4.21214 -15.29808,0c-4.21214,4.21214 -4.21214,11.08594 0,15.29808c7.54567,7.54567 17.46875,11.37019 27.70192,11.37019c11.0601,0 22.35276,-4.52224 31.00962,-13.23077l33.28365,-33.07692c8.45012,-8.42428 13.23077,-19.6911 13.23077,-31.00962c0,-10.51743 -3.97956,-20.51803 -11.37019,-27.90865c-7.90745,-7.90745 -18.52824,-11.88702 -29.76923,-11.37019zM78.55769,54.37019c-11.0601,0 -22.53365,4.54808 -31.21635,13.23077l-33.07692,33.07692c-8.45012,8.42428 -13.23077,19.69111 -13.23077,31.00962c0,10.51743 3.97957,20.51803 11.37019,27.90865c7.90746,7.90746 18.52824,11.88702 29.76923,11.37019c10.77584,-0.51683 21.13822,-5.21995 29.14904,-13.23077l31.42308,-31.42308c-4.96154,4.96154 -23.25721,1.55048 -27.70192,-2.89423l-19.01923,19.01923c-4.21214,4.21214 -9.48378,6.77044 -14.88462,7.02885c-3.66947,0.18089 -8.86358,-0.59435 -13.4375,-5.16827c-4.26382,-4.26382 -5.16827,-9.2512 -5.16827,-12.61058c0,-5.65926 2.58413,-11.29267 7.02885,-15.71154l33.07692,-33.28365c8.32092,-8.32091 20.98317,-8.96695 28.32212,-1.65385c4.21214,4.21214 11.11178,4.21214 15.29808,0c4.21214,-4.21214 4.21214,-11.08594 0,-15.29808c-7.54567,-7.54567 -17.49459,-11.37019 -27.70192,-11.37019z'></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default LinkSVG;
