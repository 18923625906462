import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DoorHandleSVG from '../components/svg/DoorHandleSVG';
import DoNotDisturbSVG from '../components/svg/DoNotDisturbSVG';
import FileSVG from '../components/svg/FileSVG';
import LinkSVG from '../components/svg/LinkSVG';
import CloudSVG from '../components/svg/CloudSVG';
import PhoneSVG from '../components/svg/PhoneSVG';
import SubscriptionCard from '../components/subsription-card/SubscriptionCard';
import LandingIcon from '../components/landing-icon/LandingIcon';
import Login from '../components/log-in/Login';
import ExternalEntryCodeForm from '../components/entry-code/ExternalEntryCodeForm';
import UseLocalStorage from '../Util/custom-hooks/UseLocalStorage';

const minWidth = 300;
const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    appBar: {
        '&.MuiPaper-elevation0': {
            background: 'white'
        },
        flexGrow: 1,
        padding: '1em',
        transition: 'linear 0.5s',
        position: 'sticky',
        top: '-1px',
        minWidth
    },
    laptop: {
        width: '100%',
        minHeight: '17vh',
        height: '100%',
        maxHeight: '65vh',
        background: 'white',
        paddingTop: '1em',
        position: 'sticky',
        top: 0,
        zIndex: -1,
        minWidth
    },
    laptopMaskWhite: {
        minWidth,
        // width: '100vw',
        height: '100%',
        background: 'white',
        position: 'relative'
    },
    title: {
        minHeight: '300px',
        transition: 'ease-in-out 0.2s',
        '& h1': {
            textShadow: `2px 2px ${theme.palette.secondary.main}`,
            [theme.breakpoints.down('xs')]: {
                fontSize: '4em'
            }
        },
        '& h6': {
            fontSize: '1.6em',
            fontWeight: 100,
            [theme.breakpoints.down('xs')]: {
                fontSize: '1em'
            }
        }
    },
    subscriptionHeader: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    img: {
        height: 'auto',
        width: '80%',
        minWidth: '320px',
        maxWidth: 'calc(65vh * 1.75)',
        maxHeight: '65vh'
    },
    bob: {
        minWidth
    },
    color: {
        minHeight: '110vh',
        background: 'white',
        width: '100%',
        padding: theme.spacing(2)
    },
    icon: {
        width: '100%',
        minWidth: '200px',
        maxWidth: '400px'
    },
    subscriptions: {
        margin: '50px 0'
    },
    subscriptionCard: {
        padding: '1em',
        minWidth: 320,
        maxWidth: 400
    }
}));

export default function MiniDrawer() {
    const classes = useStyles();
    const [directURL, setDirectURL] = UseLocalStorage(
        'external-room-url',
        /presentation\/(\w+)/g.exec(window.location.pathname)?.[1]
    );

    const [elevated, setElevated] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showJoin, setShowJoin] = useState(!!directURL);
    const [entryCode, setEntryCode] = UseLocalStorage('external-entry-code', '');

    function closeLoginDialog() {
        setShowLogin(false);
        setShowJoin(false);
    }

    useEffect(() => {
        function setElevation() {
            const header = document.querySelector('header');
            setElevated(header.getBoundingClientRect().top < 1);
        }
        window.addEventListener('scroll', setElevation);
        return () => window.removeEventListener('scroll', setElevation);
    }, []);

    return (
        <Grid container direction='column' className={classes.bob}>
            <Grid container alignItems='center' className={classes.laptop}>
                <Grid container item justify='center' alignItems='center'>
                    <img
                        src='/images/laptop-sample.png'
                        alt='computer'
                        className={classes.img}></img>
                </Grid>
            </Grid>
            <div>
                <AppBar elevation={elevated ? 8 : 0} position='static' className={classes.appBar}>
                    <Grid container justify='space-around'>
                        <Grid container item alignContent='center' justify='center' xs={3}>
                            {' '}
                            <Box pl={1}>
                                <Typography variant='h4'>VYU1</Typography>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            item
                            alignItems='center'
                            alignContent='center'
                            justify='flex-end'
                            spacing={2}
                            xs>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    size='small'
                                    color={elevated ? 'secondary' : 'primary'}
                                    onClick={() => setShowLogin(true)}>
                                    Login
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    size='small'
                                    color={elevated ? 'secondary' : 'primary'}
                                    onClick={() => setShowJoin(true)}>
                                    Join
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </AppBar>
                <Grid
                    container
                    alignItems='center'
                    alignContent='center'
                    justify='center'
                    style={{ width: '100%', background: 'white' }}>
                    <Grid item>
                        <ExternalEntryCodeForm
                            onSubmit={(ec) => {
                                setEntryCode(ec);
                                setShowLogin(true);
                            }}
                        />
                    </Grid>
                </Grid>

                {/* This container holds everything below the title bar */}
                <div className={classes.laptopMaskWhite}>
                    <Container maxwidth className={classes.color}>
                        <Grid container item justify='center' className={classes.title}>
                            <Box py={2}>
                                <Grid container justify='center' alignItems='center'>
                                    <Grid container item justify='center' direction='column'>
                                        <Typography variant='h1' align='center'>
                                            VYU1
                                        </Typography>
                                    </Grid>
                                    <Grid container item justify='center' xs={10}>
                                        <Typography
                                            variant='subtitle2'
                                            align='center'
                                            className={classes.subtitle}>
                                            Persistent hosted presentation rooms to communicate a
                                            shared vision. VYU1 allows you to create interactive
                                            spaces to discuss ideas and designs.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        {/* Icon Grid */}
                        <Grid container justify='center'>
                            <Grid container item justify='center' spacing={3} md={11}>
                                <Grid item xs={12} md={6} className={classes.icon}>
                                    <LandingIcon
                                        title='Rooms'
                                        text='Create personalized rooms to meet and collaborate with others'>
                                        <DoorHandleSVG />
                                    </LandingIcon>
                                </Grid>
                                <Grid item sm={12} md={6} className={classes.icon}>
                                    <LandingIcon
                                        title='Convenience'
                                        text='Use either public or private links to instantly share and invite others'>
                                        <LinkSVG />
                                    </LandingIcon>
                                </Grid>
                                <Grid item sm={12} md={6} className={classes.icon}>
                                    <LandingIcon
                                        title='Privacy'
                                        text='Control who has access to your rooms and your content'>
                                        <DoNotDisturbSVG />
                                    </LandingIcon>
                                </Grid>
                                <Grid item sm={12} md={6} className={classes.icon}>
                                    <LandingIcon
                                        title='Flexibility'
                                        text='Combine documents of various formats into a cohesive presentation'>
                                        <FileSVG />
                                    </LandingIcon>
                                </Grid>
                                <Grid item sm={12} md={6} className={classes.icon}>
                                    <LandingIcon
                                        title='Accessibility'
                                        text='Your rooms and data are accessible anywhere, anytime you need it'>
                                        <CloudSVG />
                                    </LandingIcon>
                                </Grid>
                                <Grid item sm={12} md={6} className={classes.icon}>
                                    <LandingIcon
                                        title='Simplicity'
                                        text='Join a presentation and contribute from any computer or device'>
                                        <PhoneSVG />
                                    </LandingIcon>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Subscription Grid */}
                        <Grid
                            container
                            justify='center'
                            spacing={2}
                            className={classes.subscriptions}>
                            <Grid item sm={12} md className={classes.subscriptionCard}>
                                <SubscriptionCard
                                    title='Starter Package'
                                    price={{ amount: 'Free' }}
                                    table={[
                                        { key: 'Rooms', value: '2' },
                                        { key: 'Participants', value: '15' },
                                        { key: 'Presentation (each)', value: '10 Slides' },
                                        { key: 'Storage (total)', value: '250 MB' }
                                    ]}
                                />
                            </Grid>

                            <Grid item sm={12} md className={classes.subscriptionCard}>
                                <SubscriptionCard
                                    title='5-Room Package'
                                    price={{ amount: '$9', frequency: 'monthly' }}
                                    table={[
                                        { key: 'Rooms', value: '5' },
                                        { key: 'Participants', value: '100' },
                                        { key: 'Presentation (each)', value: '50 Slides' },
                                        { key: 'Storage (total)', value: '5 GB' }
                                    ]}
                                />
                            </Grid>

                            <Grid item sm={12} md className={classes.subscriptionCard}>
                                <SubscriptionCard
                                    title='By the Room'
                                    price={{ amount: '$2', frequency: 'per-room, monthly' }}
                                    table={[
                                        { key: 'Additional Room', value: '1' },
                                        { key: 'Participants', value: '100' },
                                        { key: 'Presentation (each)', value: '50 Slides' },
                                        { key: 'Additional Storage', value: '500 MB' }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Login
                            open={showLogin || showJoin}
                            onClose={closeLoginDialog}
                            tab={showLogin ? 0 : 1}
                            externalEntryCode={entryCode}
                            directURL={directURL}
                        />
                    </Container>
                </div>
            </div>
        </Grid>
    );
}
