import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@material-ui/core';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

export default function ({ roomData }) {
    const [admins, setAdmins] = useState([]);
    const [online, setOnline] = useState([]);
    const findAdmins = (roommates) => {
        const roomAdmins = roommates
            .filter((r) => r.online && r.role === 'ADMIN')
            .map((r) => ({
                name: `${r.user.firstName} ${r.user.lastName}`
            }));
        setAdmins(roomAdmins || []);
    };

    const findOnline = (roommates) => {
        const roomOnline = roommates
            .filter((r) => r.online && r.role !== 'ADMIN')
            .map((r) => ({
                name: `${r.user.firstName} ${r.user.lastName}`
            }));
        setOnline(roomOnline || []);
    };

    useEffect(() => {
        // Set the data from the props if the props change
        findOnline(roomData.roommates);
        findAdmins(roomData.roommates);
    }, [roomData]);

    /*
        ROOMMATES Shape:
        [
            {
                "role": "ADMIN",
                "online": true,
                "user": {
                    "email": "raymond@hivetechnologies.net",
                    "firstName": "Raymond",
                    "lastName": "King"
                },
                "id": {
                    "roomId": "DAA3dYhn3wpM",
                    "userId": "14511"
                }
            },
            {
                "role": "USER",
                "online": true,
                "user": {
                    "email": "zachshoults@gmail.com",
                    "firstName": "Zachary",
                    "lastName": "Shoults"
                },
                "id": {
                    "roomId": "DAA3dYhn3wpM",
                    "userId": "14512"
                }
            }
        ]
    */

    return (
        <Box m={2}>
            <PanelBar>
                <PanelBarItem expanded title='Admin'>
                    <Box m={1}>
                        {admins.map((a) => (
                            <div key={a.name}>{a.name}</div>
                        ))}
                    </Box>
                </PanelBarItem>
                <PanelBarItem expanded title={'Online'}>
                    <Box p={1}>
                        {online.map((a) => (
                            <div key={a.name}>{a.name}</div>
                        ))}
                    </Box>
                </PanelBarItem>
            </PanelBar>
        </Box>
    );
}
