import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    subscriptionHeader: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    subscriptionCard: {
        minWidth: 300,
        maxWidth: 400
    }
}));

function SubscriptionCard(props) {
    const classes = useStyles();

    const { title, price, table } = props;

    return (
        <Card className={classes.subscriptionCard}>
            <CardHeader title={title} className={classes.subscriptionHeader} />
            <Box py={2}>
                <Grid container alignItems='center' justify='center'>
                    <Typography variant='h3'>{price.amount}</Typography>
                    <Typography variant='body1'>{price.frequency}</Typography>
                </Grid>
            </Box>
            <CardContent>
                <Table size='small' aria-label='a dense table'>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align='right'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table.map((t) => (
                            <TableRow key={t.key}>
                                <TableCell component='th' scope='row'>
                                    {t.key}
                                </TableCell>
                                <TableCell align='right'>{t.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}

export default SubscriptionCard;
