import React, { useRef, useEffect } from 'react';

function Canvas({ img }) {
    const canRef = useRef(); // canvas
    const ctxRef = useRef(); // canvas 2d context

    useEffect(() => {
        ctxRef.current = canRef.current.getContext('2d');
    }, [canRef]);

    const resize = () => {
        canRef.current.width = canRef.current.parentElement.clientWidth;
        canRef.current.height = canRef.current.parentElement.clientHeight;
        canRef.current.style.width = '100%';
    };

    const drawImg = () => {
        if (!img) return;

        let newWidth = canRef.current.width;
        let newHeight = canRef.current.height;

        if (img.width > img.height) {
            newHeight = (img.height * newWidth) / img.width;
        } else {
            newWidth = (img.width * newHeight) / img.height;
        }

        ctxRef.current.clearRect(0, 0, newWidth, newHeight);
        ctxRef.current.drawImage(
            img,
            (canRef.current.width - newWidth) / 2,
            (canRef.current.height - newHeight) / 2,
            newWidth,
            newHeight
        );
    };

    const update = () => {
        if (!img) return;

        resize();
        drawImg();
    };

    useEffect(() => {
        window.addEventListener('resize', update);
        return () => {
            window.removeEventListener('resize', update);
        };
    }, [img]);

    useEffect(() => update());

    return (
        <canvas
            style={{
                position: 'absolute'
            }}
            ref={canRef}
            className='child'
        />
    );
}

export default Canvas;
