import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ForgotPassword from '../components/password/ForgotPassword';

const minWidth = 300;
const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    appBar: {
        '&.MuiPaper-elevation0': {
            background: 'white'
        },
        flexGrow: 1,
        padding: '1em',
        transition: 'linear 0.5s',
        position: 'sticky',
        top: '-1px',
        minWidth
    },
    laptopMaskWhite: {
        minWidth,
        // width: '100vw',
        height: '100%',
        background: 'white',
        position: 'relative'
    },
    bob: {
        minWidth,
    },
}));

export default function ForgotPasswordPage() {
    const classes = useStyles();

    return (
        <Grid container direction='column' className={classes.bob}>
            <div>
            <AppBar position='static' className={classes.appBar}>
                    <Grid container justify='space-around'>
                        <Grid container item alignContent='center' justify='center' xs={3}>
                            {' '}
                            <Box pl={1}>
                                <Typography variant='h4'>VYU1</Typography>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            item
                            alignItems='center'
                            alignContent='center'
                            justify='flex-end'
                            spacing={2}
                            xs>
                            <Grid item>
                                {/* <Button
                                    variant='outlined'
                                    size='small'
                                    color={'primary'}
                                    onClick={() => setShowLogin(true)}>
                                    Login
                                </Button> */}
                            </Grid>
                            <Grid item>
                                {/* <Button
                                    variant='contained'
                                    size='small'
                                    color={'primary'}
                                    onClick={() => setShowJoin(true)}>
                                    Join
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </AppBar>
                {/* This container holds everything below the title bar */}
                <div className={classes.laptopMaskWhite}>
                    <Container maxwidth className={classes.color}>
                        <Box py={2}>
                            <Grid container justify='center' alignItems='center'>
                                <Grid container item justify='center' direction='column'>
                                    <Typography variant='h3' align='center'>
                                        VYU1 - Forgot Password
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} md={6} style={{ marginTop: 16 }}>
                                    <ForgotPassword/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </div>
            </div>
        </Grid>
    );
}
