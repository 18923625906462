const img = document.createElement('img');
img.src = '/images/orange-arrow.png';

// Canvas context (ctx), current point (cp) and previous point (pp)
function drawThinningTrail(ctx, cp, pp) {
    const lifePercent = cp.percentage();
    const spreadRate = 7 * (1 - lifePercent);
    ctx.beginPath();
    ctx.moveTo(pp.x, pp.y);

    // Wider line segments for the tail so it 'chases' the fade
    ctx.lineWidth = lifePercent > 0.98 ? 2 : spreadRate;
    ctx.lineJoin = 'round';

    // As time increases decrease r and b, increase g to go from purple to green.
    const red = Math.floor(190 - 190 * lifePercent);
    const green = Math.floor(200 * lifePercent);
    const blue = Math.floor(210 - 210 * lifePercent);
    ctx.strokeStyle = `rgb(${red},${green},${blue})`;

    ctx.lineTo(cp.x, cp.y);
    ctx.stroke();
    ctx.closePath();
}

function drawPointer(ctx, { x, y }, user) {
    ctx.drawImage(
        img,
        0,
        0,
        img.naturalWidth,
        img.naturalHeight, // source rectangle
        x,
        y,
        32,
        32
    ); // destination rectangle
    ctx.font = '13px Sans-serif';
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 1;
    ctx.fillStyle = 'black';
    ctx.strokeText(user, x, y + 32);
    ctx.fillText(user, x, y + 32);
}

export { drawThinningTrail, drawPointer };
