import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

export default function ({ open, onConfirm, onCancel }) {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Are you sure you want to delete this image?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='primary'>
                    Cancel
                </Button>
                <Button onClick={onConfirm} color='primary'>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
