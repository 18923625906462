import React, { useState, useRef, useEffect, useContext } from 'react';
import { drawThinningTrail, drawPointer } from './CanvasUtil';
import TrailPublisher from './TrailPublisher';
import MobileUtil from '../../Util/MobileUtil';
import UserContext from '../../App/Contexts/UserContext';
import EventManager from '../../Util/EventManager';

/* Mouse trail adapted from a jQuery Codepen by Bryan C https://codepen.io/bryjch/pen/QEoXwA */
/* https://github.com/Egrodo/noahyamamoto.com/tree/master/src */

class Point {
    constructor(rx, ry, w, h, terminal) {
        this.x = rx * w;
        this.y = ry * h;
        this.maxLife = 100;
        this.remainingLife = 100;
        this.terminal = terminal; // boolean tracking if a user has submitted an "ending" point
    }

    decrement() {
        this.remainingLife -= 1;
    }

    percentage() {
        return (100 - this.remainingLife) / 100;
    }
}

class HardPoint {
    constructor(x, y) {
        this.x = x;
        this.y = y;
        this.maxLife = 100;
        this.remainingLife = 100;
        this.terminal = true; // boolean tracking if a user has submitted an "ending" point
    }

    decrement() {
        this.remainingLife -= 1;
    }

    percentage() {
        return (100 - this.remainingLife) / 100;
    }
}

function Canvas(props) {
    const img = props.img;
    console.log('Canvas -> img:', img);
    const user = useContext(UserContext);
    // This uniqueDrawingId is used to allow your stuff to be drawn on another window, but not double drawn on this instance
    const [uniqueDrawingId] = useState(
        `${user.userId}_${Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 5)}_${user.fullName}`
    );
    const canRef = useRef(); // canvas
    const ctxRef = useRef(); // canvas 2d context
    const timeRef = useRef(0); // to track framerate
    const buttonRef = useRef(false); // is space bar pressed
    const animationRef = useRef(); // animationRequestFrame integer
    const trailPublisher = useRef();
    // need to store image ref because animation loop 'remembers' old props and will render old props
    const pathRef = useRef({
        [uniqueDrawingId]: {
            showing: [],
            pending: []
        }
    });

    useEffect(() => {
        trailPublisher.current = new TrailPublisher(
            user,
            props.roommateIds,
            props.roomId,
            uniqueDrawingId
        );
    }, [props.roommateIds, props.roomId]);

    const addRelativePoint = (user, relativePoint = {}) => {
        // If this user is brand new OR if they start a new path, reset their paths
        if (!pathRef.current[user]) {
            pathRef.current[user] = {
                showing: [],
                pending: []
            };
        } else if (
            (pathRef.current[user].pending.find((p) => p.terminal) ||
                pathRef.current[user].showing.find((p) => p.terminal)) &&
            !relativePoint.terminal
        ) {
            pathRef.current[user] = {
                showing: [],
                pending: []
            };
        }

        if (relativePoint.rx || relativePoint.terminal) {
            const { rx, ry, terminal } = relativePoint;
            const { width, height } = canRef.current;
            pathRef.current[user].pending.push(new Point(rx, ry, width, height, terminal));
        }
    };

    // Add your mouse position to your points array
    const addPoint = (x, y) => {
        const rx = x / canRef.current.width;
        const ry = y / canRef.current.height;
        const point = new Point(rx, ry, canRef.current.width, canRef.current.height);

        console.log('rx', rx);
        console.log('ry', ry);
        console.log('addPoint', point);

        if (MobileUtil.browser.isSafari()) {
            // Safari's performance degrades as more points are stored and makes the animation lag
            // https://stackoverflow.com/questions/45300903/canvas-drawing-takes-a-lot-of-time-on-safari-but-not-on-chrome-or-ff
            // To address this (right now) I'm going to only sample 60% of points on safari
            // Maybe this can be more intelligently fixed in the future.
            if (Math.random() < 0.6) {
                pathRef.current[uniqueDrawingId].pending.push(point);
                trailPublisher.current.addPoint({ x: rx, y: ry, t: Date.now() });
                // trailPublisher.current.pointsDone();
            }
        } else {
            pathRef.current[uniqueDrawingId].pending.push(point);
            trailPublisher.current.addPoint({ x: rx, y: ry, t: Date.now() });
            // trailPublisher.current.pointsDone();
        }
    };

    useEffect(() => {
        ctxRef.current = canRef.current.getContext('2d');
        const name = document.querySelector('#name');

        let mouseMoveEventListener;
        const getXY = (e) => {
            let x, y;
            if (
                e.type === 'touchstart' ||
                e.type === 'touchmove' ||
                e.type === 'touchend' ||
                e.type === 'touchcancel'
            ) {
                var touch = e.touches[0];
                x = touch.clientX;
                y = touch.clientY;
            } else if (
                e.type === 'mousedown' ||
                e.type === 'mouseup' ||
                e.type === 'mousemove' ||
                e.type === 'mouseover' ||
                e.type === 'mouseout' ||
                e.type === 'mouseenter' ||
                e.type === 'mouseleave'
            ) {
                x = e.clientX;
                y = e.clientY;
            }

            let rect = canRef.current.getBoundingClientRect();
            x -= rect.left;
            y -= rect.top;
            return { x, y };
        };

        if (MobileUtil.browser.isSafari()) {
            mouseMoveEventListener = (e) => {
                e.preventDefault();
                name.innerText = user.firstName;
                name.style.zIndex = 2000;
                name.style.left = `${e.clientX}px`;
                name.style.top = `${e.clientY + 30}px`;
                const { x, y } = getXY(e);
                if (buttonRef.current) {
                    addPoint(x, y);
                }
            };
        } else {
            mouseMoveEventListener = (e) => {
                e.preventDefault();
                name.innerText = user.firstName;
                name.style.zIndex = 2000;
                name.style.left = `${e.clientX}px`;
                name.style.top = `${e.clientY + 30}px`;
                const { x, y } = getXY(e);
                if (buttonRef.current) {
                    addPoint(x, y);
                }
            };
        }

        EventManager.reactPathEvent((e) => {
            const msg = JSON.parse(e.data);

            if (document.visibilityState !== 'visible') {
                // don't add points if the user is not looking at the page ("latent drawing")
                return;
            }

            if (msg.roomId != props.roomId || msg.uniqueDrawingId == uniqueDrawingId) {
                // If the user is logged into multiple rooms at once, only draw
                // the points for the room they belong to
                return;
            }

            const trail = msg.points;
            trail.sort((a, b) => a.t - b.t);
            trail.forEach(({ x, y, terminal }) => {
                addRelativePoint(msg.uniqueDrawingId, { rx: x, ry: y, terminal });
            });
        });

        canRef.current.addEventListener('mousemove', mouseMoveEventListener, false);
        canRef.current.addEventListener('touchmove', mouseMoveEventListener, { passive: false });

        const mouseDownEventListener = (e) => {
            pathRef.current[uniqueDrawingId] = { showing: [], pending: [] };
            buttonRef.current = true;
            // trailPublisher.current.pointsDone();
        };

        const mouseLeaveEventListener = (e) => {
            name.innerText = '';
            // mouseUpEventListener(e);
            trailPublisher.current.pointsDone();
        };

        const mouseUpEventListener = (e) => {
            buttonRef.current = false;
            trailPublisher.current.pointsDone();
        };

        canRef.current.addEventListener('mousedown', mouseDownEventListener);
        canRef.current.addEventListener('touchstart', mouseDownEventListener);
        canRef.current.addEventListener('mouseup', mouseUpEventListener);
        canRef.current.addEventListener('touchend', mouseLeaveEventListener);
        canRef.current.addEventListener('mouseleave', mouseLeaveEventListener);
        window.addEventListener('resize', resize);

        return () => {
            canRef.current?.removeEventListener('mousemove', mouseMoveEventListener);
            canRef.current?.removeEventListener('touchmove', mouseMoveEventListener);
            canRef.current?.removeEventListener('mousedown', mouseDownEventListener);
            canRef.current?.removeEventListener('touchstart', mouseDownEventListener);
            canRef.current?.removeEventListener('mouseUp', mouseUpEventListener);
            canRef.current?.removeEventListener('touchend', mouseLeaveEventListener);
            canRef.current?.removeEventListener('mouseleave', mouseLeaveEventListener);
            window.removeEventListener('resize', resize);
            name.innerText = '';
            EventManager.unsetPathEvent();
        };
    }, [canRef]);

    const startAnimation = () => {
        const FPS = 16; // 16 -> 60fps, 22 -> 45fps
        const animatePoints = (time = 0) => {
            const elapsed = time - timeRef.current;
            // if enough time has passed to warrant the next frame, animate. (60fps)
            if (elapsed >= FPS) {
                ctxRef.current.clearRect(
                    0,
                    0,
                    ctxRef.current.canvas.width,
                    ctxRef.current.canvas.height
                );
                timeRef.current = time - (elapsed % FPS);
                const usernames = Object.keys(pathRef.current);

                for (let j = 0; j < usernames.length; j++) {
                    const userId = usernames[j];

                    if (pathRef.current[userId].pending.length) {
                        pathRef.current[userId].showing.push(
                            pathRef.current[userId].pending.shift()
                        );
                    }

                    if (pathRef.current[userId].showing.length) {
                        for (let i = 0; i < pathRef.current[userId].showing.length; ++i) {
                            let [head] = pathRef.current[userId].showing.slice(-1);

                            // When sending a trail over the event, there is a "terminal" point
                            // which acts like a null-terminated character. We try to avoid that
                            // point in the array by using the following logic
                            if (!head.x || !head.y) {
                                try {
                                    head = pathRef.current[userId].showing.slice(-2)[0];
                                } catch {
                                    alert('cannot');
                                }
                            }
                            const point = pathRef.current[userId].showing[i];
                            let lastPoint;

                            if (pathRef.current[userId].showing[i - 1] !== undefined) {
                                lastPoint = pathRef.current[userId].showing[i - 1];
                            } else {
                                lastPoint = point;
                            }
                            point.decrement();

                            if (point.remainingLife <= 0) {
                                // If the point dies, remove it.
                                pathRef.current[userId].showing.shift();
                            } else {
                                // //DR test code.
                                // let point1 = new HardPoint(0, 0);
                                // let point2 = new HardPoint(10, 10);
                                // drawThinningTrail(ctxRef.current, point1, point2);
                                // let point3 = new HardPoint(
                                //     ctxRef.current.canvas.width,
                                //     ctxRef.current.canvas.height
                                // );
                                // let point4 = new HardPoint(
                                //     ctxRef.current.canvas.width - 10,
                                //     ctxRef.current.canvas.height - 10
                                // );
                                // drawThinningTrail(ctxRef.current, point3, point4);

                                // console.log('drawing thinning trail', point, lastPoint);
                                // console.log('head', head);
                                drawThinningTrail(ctxRef.current, point, lastPoint);
                                if (userId !== uniqueDrawingId) {
                                    drawPointer(
                                        ctxRef.current,
                                        head,
                                        userId.split('_')[2] || 'something'
                                    );
                                }
                            }
                        }
                    }
                }
            }
            animationRef.current = requestAnimationFrame((time) => animatePoints(time));
        };

        animatePoints();
    };

    const resize = () => {
        if (!img) return;

        canRef.current.width = canRef.current.parentElement.clientWidth;
        canRef.current.height = canRef.current.parentElement.clientHeight;
        canRef.current.style.width = '100%';

        // let newWidth = img.width;
        // let newHeight = img.height;

        // if (img.width > img.height) {
        //     newHeight = (img.height * newWidth) / img.width;
        // } else {
        //     newWidth = (img.width * newHeight) / img.height;
        // }

        // canRef.current.width = newWidth;
        // canRef.current.height = newHeight;
        ctxRef.current = canRef.current.getContext('2d');
    };

    const update = () => {
        resize();
    };

    useEffect(() => update());

    useEffect(() => {
        startAnimation();
        window.addEventListener('resize', update);
        return () => {
            window.removeEventListener('resize', update);
            cancelAnimationFrame(animationRef.current);
        };
    }, [props.img]);

    return (
        <canvas
            style={{
                position: 'absolute',
                zIndex: '5', // to make this canvas always above the other canvas
                cursor: props.cursor
                // border: '1px solid black'
            }}
            ref={canRef}
            className='child'
        />
    );
}

export default Canvas;
