import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ResetPassword from '../components/password/ResetPassword';
import UserService from '../services/UserService';

const minWidth = 300;
const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    appBar: {
        '&.MuiPaper-elevation0': {
            background: 'white'
        },
        flexGrow: 1,
        padding: '1em',
        transition: 'linear 0.5s',
        position: 'sticky',
        top: '-1px',
        minWidth
    },
    laptopMaskWhite: {
        minWidth,
        // width: '100vw',
        height: '100%',
        background: 'white',
        position: 'relative'
    },
    bob: {
        minWidth,
    },
}));

export default function ResetPasswordPage() {
    const classes = useStyles();
    const { token } = useParams(); // reset token from url
    const [loading, setLoading] = useState(true);
    const [tokenError, setTokenError] = useState(false);

    // lookup the reset token
    useEffect(() => {
        try {
            UserService.checkPasswordResetToken(token)
            .catch((error) => setTokenError(error.response.data.message))
            .then((data) => {
                setLoading(false);
            });
        } catch(err) {
            setTokenError(true)
            setLoading(false);
        }
    }, [token]);

    if(loading) return null;

    return (
        <Grid container direction='column' className={classes.bob}>
            <div>
            <AppBar position='static' className={classes.appBar}>
                    <Grid container justify='space-around'>
                        <Grid container item alignContent='center' justify='center' xs={3}>
                            {' '}
                            <Box pl={1}>
                                <Typography variant='h4'>VYU1</Typography>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            item
                            alignItems='center'
                            alignContent='center'
                            justify='flex-end'
                            spacing={2}
                            xs>
                            <Grid item>
                                {/* <Button
                                    variant='outlined'
                                    size='small'
                                    color={'primary'}
                                    onClick={() => setShowLogin(true)}>
                                    Login
                                </Button> */}
                            </Grid>
                            <Grid item>
                                {/* <Button
                                    variant='contained'
                                    size='small'
                                    color={'primary'}
                                    onClick={() => setShowJoin(true)}>
                                    Join
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </AppBar>
                {/* This container holds everything below the title bar */}
                <div className={classes.laptopMaskWhite}>
                    <Container maxwidth className={classes.color}>
                        <Box py={2}>
                            <Grid container justify='center' alignItems='center'>
                                <Grid container item justify='center' direction='column'>
                                    <Typography variant='h3' align='center'>
                                        VYU1 - Reset Password
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} md={6} style={{ marginTop: 16 }}>
                                    {!!token || tokenError ? 
                                        <Typography variant="p" direction="column">
                                            The password reset token is invalid or timed out. Please use the <Link to="/forgot-password">forgot password</Link> form and try again.
                                        </Typography> :
                                        <ResetPassword/>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </div>
            </div>
        </Grid>
    );
}
