import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import RoomService from '../../services/RoomService';
import CircularProgress from '@material-ui/core/CircularProgress';
import MessageContext from '../../App/Contexts/MessageContext';

export default function ({ initialEntryCode = '' }) {
    const history = useHistory();
    const [entryCode, setEntryCode] = useState(initialEntryCode);
    const [loading, setLoading] = useState(false);
    const { setMessage } = useContext(MessageContext);

    const proceedToRoom = (e) => {
        e.preventDefault();
        if (!entryCode) {
            return setMessage('must enter an entry code');
        }
        setLoading(true);

        return RoomService.entryCodePass(entryCode)
            .then((room) => {
                history.push(`/presentation/${room.url}`);
            })
            .catch((e) => {
                setLoading(false);
                setMessage(e.message);
            });
    };

    return (
        <form style={{ width: '100%' }} onSubmit={proceedToRoom}>
            <Grid container spacing={1}>
                <Grid item>
                    <TextField
                        size='small'
                        label='Entry Code'
                        type='text'
                        variant='outlined'
                        fullWidth
                        disabled={loading}
                        onChange={({ target: { value } }) => setEntryCode(value.toUpperCase())}
                        value={entryCode}
                    />
                </Grid>
                <Grid item>
                    <FormControl>
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={!entryCode || loading}
                            onClick={proceedToRoom}>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item>Proceed</Grid>
                                {loading ? (
                                    <Grid item>
                                        <CircularProgress color='secondary' size={14} />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    );
}
