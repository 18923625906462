import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

function LandingIcon({ children, title, text }) {
    return (
        <Card>
            <Box p={2}>
                <Grid container justify='center'>
                    <Grid item xs={2}>
                        {children}
                    </Grid>
                </Grid>

                <CardContent>
                    <Grid container justify='center'>
                        <Typography component='h6' variant='h6'>
                            {title}
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary' align='center'>
                            {text}
                        </Typography>
                    </Grid>
                </CardContent>
            </Box>
        </Card>
    );
}

export default LandingIcon;
