import axios from '../Util/AxiosIntercepted';

/*
{
    query:"
        mutation ($room: NewRoom!) {
            createRoom(room: $room) {
                creator {
                userId
                username
            }
            name
            roommates {
                role
                id {
                    roomId
                    userId
            }
            banned
            }
            roomId
            url
        }
        }",

    variables:{"room":{"creator":{"userId":"14512"},"name":"Stephen King Discussion3","url":"fhuw8AEkl"}}}
*/

function getUrl() {
    const request = {
        query: '{ retrieveRandomUrl }',
        variables: {}
    };
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { retrieveRandomUrl }
            }
        }) => retrieveRandomUrl
    );
}

async function createRoom(userId, roomName, roomPassword) {
    let url, entryCode;
    try {
        url = await getUrl();
        entryCode = await getEntryCode();
        entryCode = entryCode.toUpperCase().substr(0, 6);
    } catch (e) {
        throw e;
    }
    const query = `
    mutation($room:NewRoom!) { 
        createRoom(room:$room) { 
            roomId,
            url,
        } 
    }`;
    const variables = {
        room: {
            creator: { userId },
            name: roomName,
            entryCode,
            url,
            ...(roomPassword ? { password: roomPassword } : {})
        }
    };
    const request = {
        query,
        variables
    };
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(({ data }) => {
    return axios.post(`/vyu/graphql`, request).then(({ data }) => {
        return data;
    });
}

function entryCodePass(entryCode) {
    const query = `
        mutation($entryCode: String!) {
            entryCodePass(entryCode: $entryCode) {
                creator {
                    userId
                }
                entryCode
                roomId
                name
                url
                password
                roommates {
                    id {
                        userId
                    }
                }
                presentationItems { 
                    itemId 
                    itemName 
                    mediaType 
                    uploader 
                    uploadTime 
                    visible
                    index
                }
                roommates {
                    user {
                        email
                    }
                }
            }
        }
    `;

    const request = {
        query,
        variables: { entryCode }
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(({ data }) => {
    return axios.post(`/vyu/graphql`, request).then(({ data }) => {
        return data.data.entryCodePass;
    });
}

function getUserRooms(userId) {
    const query = `
    {
        getRoomsByUserId(userId: "${userId}") {
            roomId
            creator {
                userId
            }
            name
            url
            password
            entryCode
            roommates {
                id {
                    userId
                }
                lastSeen
            }
        }
    }`;

    const request = {
        query,
        variables: {}
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { getRoomsByUserId }
            }
        }) => getRoomsByUserId
    );
}

function deleteRoom(roomId) {
    const query = `
        mutation { 
            deleteRoom(roomId:"${roomId}")
        }`;

    const request = {
        query,
        variables: {}
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request);
    return axios.post(`/vyu/graphql`, request);
}

function getRoomByRoomUrl(url) {
    const query = `
        query($url: String!) {
            getRoomByRoomUrl(url: $url) {
                creator {
                    userId
                }
                roomId
                name
                url
                password
                entryCode
                presentationItems { 
                    itemId 
                    itemName 
                    mediaType 
                    uploader 
                    uploadTime 
                    visible
                    index
                    selected
                }
                roommates {
                    role
                    online
                    user {
                        email
                        firstName
                        lastName
                    }
                    id { roomId userId }
                }
            }
        }
    `;

    const request = {
        query,
        variables: { url }
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { getRoomByRoomUrl }
            }
        }) => getRoomByRoomUrl
    );
}

function getPresentationForRoomUrl(url) {
    const query = `
        query($url: String!) {
            getRoomByRoomUrl(url: $url) {
                roomId
                presentationItems { 
                    itemId 
                    itemName 
                    mediaType 
                    uploader 
                    uploadTime 
                    visible
                    index
                    selected
                }
            }
        }
    `;

    const request = {
        query,
        variables: { url }
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { getRoomByRoomUrl }
            }
        }) => getRoomByRoomUrl
    );
}

function roomLogin(url) {
    const query = `
        mutation($url: String!) {
            roomLogin(url: $url) {
                creator {
                    userId
                }
                roomId
                name
                url
                password
                entryCode
                presentationItems { 
                    itemId 
                    itemName 
                    mediaType 
                    uploader 
                    uploadTime 
                    visible
                    index
                    selected
                }
                roommates {
                    role
                    online
                    user {
                        email
                        firstName
                        lastName
                    }
                    id { roomId userId }
                }
            }
        }
    `;

    const request = {
        query,
        variables: { url }
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { roomLogin }
            }
        }) => {
            return roomLogin;
        }
    );
}

function setActiveImageInPresentation(roomId, presentationItemId) {
    const query = `
        mutation($roomId: String!, $presentationItemId: String!) {
            setSelectedPresentationItem(roomId:$roomId, presentationItemId: $presentationItemId)
        }
    `;

    const request = {
        query,
        variables: { roomId, presentationItemId }
    };

    // The response is insignificant
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(() => {});
    return axios.post(`/vyu/graphql`, request).then(() => {});
}

function getEntryCode() {
    const request = {
        query: '{ retrieveRandomEntryCode }',
        variables: {}
    };
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { retrieveRandomEntryCode }
            }
        }) => retrieveRandomEntryCode
    );
}

function heartbeat(roomId, userId) {
    // No return value. Just want to optimistically call it. No UI code will care about the success/failure of heartbeat
    axios
        // .post(`${process.env.REACT_APP_EVENTS_HOST}/events/heartbeat`, { roomId, userId })
        .post(`/events/heartbeat`, { roomId, userId })
        .then((r) => {})
        .catch(console.error);
}

export default {
    createRoom,
    getUserRooms,
    getRoomByRoomUrl,
    entryCodePass,
    deleteRoom,
    roomLogin,
    getPresentationForRoomUrl,
    setActiveImageInPresentation,
    getEntryCode,
    heartbeat
};
