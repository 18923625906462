import React, { useContext, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MessageContext from '../../App/Contexts/MessageContext';

const useStyles = makeStyles((theme) => {
    const tab = {
        width: '100%',
        height: 0,
        position: 'absolute',
        bottom: 0,
        left: 0,
        transition: 'all 0.5s',
        color: 'rgba(0,0,0,0)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        margin: 'unset',
        paddingTop: '12px',
        cursor: 'text',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.typography.subtitle1
    };
    return {
        dark: {
            ...tab,
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        light: {
            ...tab,
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }
    };
});

// Menu should contain objects like: { displayText: 'delete', callback: () => { ... } }
function CardTabSlide({ text, dark, menu = [] }) {
    const styles = useStyles();
    const { setMessage } = useContext(MessageContext);
    const menuParentRef = useRef();
    const [menuOpen, setMenuOpen] = useState(false);

    const options = menu.map(({ displayText }) => displayText);

    // Function to copy text to clipboard
    function onTabClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.textContent === text) {
            navigator.clipboard.writeText(event.target.textContent);
            setMessage('Copied to clipboard: ' + event.target.textContent);
        }
    }

    function onMenuOptionClick(event, displayText) {
        event.stopPropagation();
        event.preventDefault();
        const selection = menu.find((option) => option.displayText === displayText);
        setMenuOpen(false);
        selection.callback();
    }

    return (
        <span>
            <dd className={dark ? styles.dark : styles.light} onClick={onTabClick}>
                <span style={{ width: '100%', textAlign: 'center' }}>{text}</span>
                {menu.length ? (
                    <>
                        <IconButton
                            style={{ padding: 0, outline: 0 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setMenuOpen(true);
                            }}
                            ref={menuParentRef}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id='long-menu'
                            anchorEl={menuParentRef.current}
                            keepMounted
                            open={menuOpen}
                            onClose={() => setMenuOpen(false)}>
                            {options.map((displayText) => (
                                <MenuItem
                                    key={displayText}
                                    onClick={(event) => onMenuOptionClick(event, displayText)}>
                                    {displayText}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : null}
            </dd>
        </span>
    );
}

export default CardTabSlide;
