import React from 'react';

export default function (props) {
    const { slide = { burl: '', index: -199 }, focused, imgClick } = props;
    const loading = !slide.burl;
    // image that says "Upload an image to get started"
    const getStartedURL = 'https://i.imgur.com/BdbjnMF.jpg';
    // image that says "Grabbing your presentation. Just a sec"
    const loadingPresentation = 'https://i.imgur.com/FnSf0dL.jpg';
    // image that is just gray
    const loadingGray =
        'https://www.virtualdatapartners.com/wp-content/uploads/2016/06/Gray-background-3.jpg';

    let image = slide.burl;

    if (loading && slide.index > -1) {
        if (focused) {
            image = loadingPresentation;
        } else {
            image = loadingGray;
        }
    } else if (slide.index < 0) {
        image = getStartedURL;
    }

    const styles = props.style || {};

    // The flex-start alignment is to help make the behavior of chrome and safari alike.
    // Makes 'height: auto' within a flex container put the image at
    // the start of the container and not behave like 'height: 100%' (in safari)
    return (
        <img
            key={slide.index}
            style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                ...styles
            }}
            alt={`img-${slide.index}`}
            src={image}
            onMouseDown={(e) => e.preventDefault()}
            onClick={({ target }) => {
                imgClick(target);
            }}></img>
    );
}
