import React, { useContext, useState, useEffect } from 'react';
import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined';
import { Typography, Grid } from '@material-ui/core';
import CardBase from './CardBase';
import SubscriptionService from '../../services/SubscriptionService';
import RoomService from '../../services/RoomService';
import MessageContext from '../../App/Contexts/MessageContext';
import UserContext from '../../App/Contexts/UserContext';

function CreateRoomCard(props) {
    const [subscriptionLimit, setSubscriptionLimit] = useState(0);
    const { userId } = useContext(UserContext);

    useEffect(() => {
        SubscriptionService.getUserSubscriptionData(userId)
            .then((subscription) => {
                setSubscriptionLimit(subscription.numOfRooms);
            })
            .catch(console.error);
    });

    return (
        <CardBase dark tabText={`${subscriptionLimit - props.rooms.length} rooms left`} {...props}>
            <Grid container justify='center'>
                <Typography color='textSecondary' gutterBottom>
                    Create
                </Typography>
            </Grid>
            <Grid container justify='center'>
                <QueueOutlinedIcon />
            </Grid>
        </CardBase>
    );
}

export default CreateRoomCard;
