import React from 'react';
import { Typography } from '@material-ui/core';
import CardBase from './CardBase';

function RoomCard(props) {
    const { title, entryCode } = props;

    return (
        <CardBase tabText={entryCode} {...props}>
            <Typography color='textSecondary' gutterBottom>
                {title.length > 35 ? title.substr(0, 35) + '...' : title}
            </Typography>
        </CardBase>
    );
}

export default RoomCard;
