import events from 'events';

const Events = {
    LOGIN_USER: 'LOGIN_USER',
    KILL_USER: 'KILL_USER',
    ROOM_EVENT: 'ROOM_EVENT',
    CHAT_EVENT: 'CHAT_EVENT',
    CREATE_A_ROOM: 'CREATE_A_ROOM',
    DELETE_A_ROOM: 'DELETE_A_ROOM',
    PATH_EVENT: 'PATH_EVENT',
    ROOM_CONNECT: 'ROOM_CONNECT',
    ACTIVE_IMAGE: 'ACTIVE_IMAGE'
};

class EventManager {
    constructor() {
        this.eventEmitter = new events.EventEmitter();
    }
    emitLogin(user) {
        this.eventEmitter.emit(Events.LOGIN_USER, user);
    }
    listenLoginUser(fn) {
        this.eventEmitter.on(Events.LOGIN_USER, fn);
    }
    unsetLoginUser() {
        this.eventEmitter.removeAllListeners(Events.LOGIN_USER);
    }

    emitKillUser(user) {
        this.eventEmitter.emit(Events.KILL_USER, user);
    }
    listenKillUser(fn) {
        this.eventEmitter.on(Events.KILL_USER, fn);
    }
    unsetKillUser() {
        this.eventEmitter.removeAllListeners(Events.KILL_USER);
    }

    emitRoomEvent(event) {
        this.eventEmitter.emit(Events.ROOM_EVENT, event);
    }
    reactRoomEvent(fn) {
        this.eventEmitter.on(Events.ROOM_EVENT, fn);
    }
    unsetRoomEvent() {
        this.eventEmitter.removeAllListeners(Events.ROOM_EVENT);
    }

    emitChatEvent() {
        this.eventEmitter.emit(Events.CHAT_EVENT, {});
    }
    reactChatEvent(fn) {
        this.eventEmitter.on(Events.CHAT_EVENT, fn);
    }
    unsetChatEvent() {
        this.eventEmitter.removeAllListeners(Events.CHAT_EVENT);
    }

    emitCreateARoomEvent(event) {
        this.eventEmitter.emit(Events.CREATE_A_ROOM, event);
    }
    reactCreateARoomEvent(fn) {
        this.eventEmitter.on(Events.CREATE_A_ROOM, fn);
    }
    unsetCreateARoomEvent() {
        this.eventEmitter.removeAllListeners(Events.CREATE_A_ROOM);
    }

    emitDeleteARoomEvent() {
        this.eventEmitter.emit(Events.DELETE_A_ROOM, {});
    }
    reactDeleteARoomEvent(fn) {
        this.eventEmitter.on(Events.DELETE_A_ROOM, fn);
    }
    unsetDeleteARoomEvent() {
        this.eventEmitter.removeAllListeners(Events.DELETE_A_ROOM);
    }

    emitConnectEvent(user) {
        // user variable will look like: {"userId":"14511","email":"raymond@hivetechnologies.net","username":"raymond@hivetechnologies.net","firstName":"Raymond","lastName":"King","enabled":true,"role":[{"roleId":14505,"name":"ROLE_ADMIN"}]}
        this.eventEmitter.emit(Events.ROOM_CONNECT, user);
    }
    reactConnectEvent(fn) {
        this.eventEmitter.on(Events.ROOM_CONNECT, (d) => {
            console.log('connect', d);
            fn(d);
        });
    }
    unsetConnectEvent() {
        this.eventEmitter.removeAllListeners(Events.ROOM_CONNECT);
    }

    emitPathEvent(e) {
        this.eventEmitter.emit(Events.PATH_EVENT, e);
    }
    reactPathEvent(fn) {
        this.eventEmitter.on(Events.PATH_EVENT, fn);
    }
    unsetPathEvent() {
        this.eventEmitter.removeAllListeners(Events.PATH_EVENT);
    }
}

export default new EventManager();
