import React, { useState, useEffect, useRef } from 'react';
import Canvas from '../canvas/Canvas';
import ImgCanvas from '../canvas/ImgCanvas';

function ContainedSlide({ cursor, img = document.createElement('img'), roommateIds = [], roomId }) {
    return (
        <div
            style={{
                position: 'relative',
                maxHeight: 'calc(100% - 20px)',
                height: '100%',
                width: '100%',
                maxWidth: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
            <ImgCanvas img={img} />
            <Canvas cursor={cursor} img={img} roommateIds={roommateIds} roomId={roomId} />
        </div>
    );
}

function FullScreenSlide({
    cursor,
    img = document.createElement('img'),
    roommateIds = [],
    roomId
}) {
    return (
        <div
            className='hide-scrollbars'
            style={{
                position: 'absolute',
                height: '100vh',
                width: '100vw',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                background: 'black',
                zIndex: 1198
            }}
            onTouchMove={(e) => e.preventDefault()}
            onMouseMove={(e) => e.preventDefault()}>
            <ImgCanvas img={img} />
            <Canvas cursor={cursor} img={img} roommateIds={roommateIds} roomId={roomId} />
        </div>
    );
}

const Vyur = ({
    fullscreen,
    img = document.createElement('img'),
    roommateIds = [],
    roomId,
    cursor
}) => {
    const divRef = useRef();

    const [newImage, setNewImage] = useState(null);

    useEffect(() => {
        const image = new Image();
        if (typeof img.src !== 'string') {
            const blob = URL.createObjectURL(new Blob(img.src), {
                type: 'image/jpeg',
                quality: 1,
                width: img.width,
                height: img.height
            });
            image.src = blob;
        } else {
            image.src = img.src;
        }

        setNewImage(image);
    }, [fullscreen, img, roommateIds, roomId, cursor]);

    return (
        <div
            ref={divRef}
            style={{
                position: 'relative',
                maxHeight: 'calc(100% - 20px)',
                height: '100%',
                width: '100%',
                maxWidth: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
            {fullscreen ? (
                <FullScreenSlide
                    cursor={cursor}
                    img={newImage}
                    roommateIds={roommateIds}
                    roomId={roomId}
                />
            ) : (
                <ContainedSlide
                    cursor={cursor}
                    img={newImage}
                    roommateIds={roommateIds}
                    roomId={roomId}
                />
            )}
        </div>
    );
};

export default Vyur;
