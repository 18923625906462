export default {
    size: {
        isMobile: () => window.innerWidth <= 500 || window.innerHeight <= 500
    },
    browser: {
        isSafari: () =>
            navigator.vendor &&
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1
    }
};
