import axios from '../Util/AxiosIntercepted';

function getChatMessagesByRoomId(roomId) {
    const query = `
    {
        getChatMessagesByRoomId(roomId: "${roomId}") {
            chatMessageId
            chatMessageDate
            sender {
                userId
                email
                firstName
                lastName
            }
            text
        }
    }`;

    const request = {
        query,
        variables: {}
    };

    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { getChatMessagesByRoomId }
            }
        }) => {
            return getChatMessagesByRoomId;
        }
    );
}

async function createChatMessage(roomId, text, chatMessageDate) {
    const query = `
    mutation($chatMessage:NewChatMessage!) { 
        createChatMessage(roomId:"${roomId}", chatMessage:$chatMessage) { 
            chatMessageId
            chatMessageDate
            room {
                roomId
            }
            text
        } 
    }`;
    const variables = {
        chatMessage: {
            text,
            chatMessageDate
        }
    };
    const request = {
        query,
        variables
    };
    // return axios.post(`${process.env.REACT_APP_API_HOST}/vyu/graphql`, request).then(
    return axios.post(`/vyu/graphql`, request).then(
        ({
            data: {
                data: { createChatMessage }
            }
        }) => {
            return createChatMessage;
        }
    );
}
export default {
    createChatMessage,
    getChatMessagesByRoomId
};
